import React, {
  createContext,
  CSSProperties,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocalStorage } from '@mantine/hooks';
import { Tabs } from '@mantine/core';
import { ChatRoom } from './ChatRoom';
import { SearchResultList } from './SearchResults';

export type UseSearchHistoryEntrySectionsResultType = {
  activeTab: 'chat' | 'searchResults';
  setActiveTab: ReturnType<typeof useLocalStorage<UseSearchHistoryEntrySectionsResultType['activeTab']>>[1];
  withChat: boolean;
};

export const SearchHistoryEntrySectionsContext = createContext<UseSearchHistoryEntrySectionsResultType | undefined>(
  undefined,
);
SearchHistoryEntrySectionsContext.displayName = 'SearchHistoryEntrySectionsContext';

export const useSearchHistoryEntrySections = (): UseSearchHistoryEntrySectionsResultType => {
  const context = useContext(SearchHistoryEntrySectionsContext);
  if (!context) {
    throw new Error('useSearchHistoryEntrySectionsContext must be used within SearchHistoryEntrySectionsProvider');
  }
  return context as UseSearchHistoryEntrySectionsResultType;
};

export const SearchHistoryEntrySections: React.FC<PropsWithChildren<{ withChat: boolean }>> = ({
  children,
  withChat,
}) => {
  // const [activeTab, setActiveTab] = useState<UseSearchHistoryEntrySectionsResultType['activeTab']>('chat');
  const [activeTab, setActiveTab] = useLocalStorage<UseSearchHistoryEntrySectionsResultType['activeTab']>({
    key: 'search-entry-tab',
    defaultValue: 'searchResults',
  });

  const SearchHistoryEntrySectionsContextObject: UseSearchHistoryEntrySectionsResultType = useMemo(
    () => ({
      activeTab,
      setActiveTab,
      withChat,
    }),
    [activeTab, withChat],
  );

  return (
    <SearchHistoryEntrySectionsContext.Provider value={SearchHistoryEntrySectionsContextObject}>
      {withChat && activeTab === 'chat' ? <ChatRoom /> : <SearchResultList />}
    </SearchHistoryEntrySectionsContext.Provider>
  );
};
