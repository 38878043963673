import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  URL: { input: any; output: any };
  UUID: { input: any; output: any };
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

/** Chat message */
export type ChatMessageDto = {
  __typename?: 'ChatMessageDto';
  /** Chat message creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Chat message identifier */
  id: Scalars['UUID']['output'];
  /** Rating of the chat message */
  rating: RatingDto;
  /** Chat message text */
  text: Scalars['String']['output'];
  /** Chat message type */
  type: ChatMessageType;
};

/** Chat message */
export type ChatMessageDtoFilterInput = {
  and?: InputMaybe<Array<ChatMessageDtoFilterInput>>;
  /** Chat message creation date */
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Chat message identifier */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ChatMessageDtoFilterInput>>;
  /** Rating of the chat message */
  rating?: InputMaybe<RatingDtoFilterInput>;
  /** Chat message text */
  text?: InputMaybe<StringOperationFilterInput>;
  /** Chat message type */
  type?: InputMaybe<ChatMessageTypeOperationFilterInput>;
};

/** Chat message type */
export enum ChatMessageType {
  /** System message */
  System = 'SYSTEM',
  /** User message */
  User = 'USER',
}

export type ChatMessageTypeOperationFilterInput = {
  eq?: InputMaybe<ChatMessageType>;
  in?: InputMaybe<Array<ChatMessageType>>;
  neq?: InputMaybe<ChatMessageType>;
  nin?: InputMaybe<Array<ChatMessageType>>;
};

/** Chunk of the chat reply */
export type ChatTokenDto = {
  __typename?: 'ChatTokenDto';
  /** Word */
  token: Scalars['String']['output'];
};

/** Chat reply stream */
export type ChatTokenStreamDto = {
  __typename?: 'ChatTokenStreamDto';
  /** Stream identifier */
  id: Scalars['UUID']['output'];
  /** List of tokens */
  tokens: Array<ChatTokenDto>;
};

export type ClearHistoryPayload = {
  __typename?: 'ClearHistoryPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeleteQueryInput = {
  /** Query ID */
  id: Scalars['UUID']['input'];
};

export type DeleteQueryPayload = {
  __typename?: 'DeleteQueryPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

/** Folder DTO */
export type FolderDto = {
  __typename?: 'FolderDto';
  /** Is checked by default */
  checked: Scalars['Boolean']['output'];
  /** Folder identifier */
  id: Scalars['String']['output'];
  /** Folder parent identifier */
  parentId?: Maybe<Scalars['String']['output']>;
  /** Folder title */
  title: Scalars['String']['output'];
};

/** Post reply to user message input */
export type GenerateReplyToUserMessageInput = {
  /** MessageId is the id of the reply message. */
  systemMessageId: Scalars['UUID']['input'];
  /** Existing user message to post reply to */
  userMessageId: Scalars['UUID']['input'];
};

export type GenerateReplyToUserMessagePayload = {
  __typename?: 'GenerateReplyToUserMessagePayload';
  chatTokenStreamDto?: Maybe<ChatTokenStreamDto>;
};

/** History entry DTO */
export type HistoryEntry = KeywordHistoryEntryDto | SemanticHistoryEntryDto;

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

/** Keyword history entry DTO */
export type KeywordHistoryEntryDto = {
  __typename?: 'KeywordHistoryEntryDto';
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Folder IDs */
  folders: Array<Scalars['String']['output']>;
  /** Keyword history entry identifier */
  id: Scalars['UUID']['output'];
  /** Query text */
  query: Scalars['String']['output'];
  /** List of search results */
  searchResults: Array<KeywordSearchResultDto>;
};

/** Keyword history entry DTO */
export type KeywordHistoryEntryDtoSearchResultsArgs = {
  where?: InputMaybe<KeywordSearchResultDtoFilterInput>;
};

/** Keyword search result DTO */
export type KeywordSearchResultDto = {
  __typename?: 'KeywordSearchResultDto';
  /** Order of the result */
  order: Scalars['Int']['output'];
  /** Rating */
  rating: RatingDto;
  /** Reference code of the result. */
  referenceCode: Scalars['String']['output'];
  /** Score */
  score: Scalars['Float']['output'];
  /** Snippet of the result. */
  snippet: Scalars['String']['output'];
  /** Result URI */
  uri?: Maybe<Scalars['URL']['output']>;
};

/** Keyword search result DTO */
export type KeywordSearchResultDtoFilterInput = {
  and?: InputMaybe<Array<KeywordSearchResultDtoFilterInput>>;
  or?: InputMaybe<Array<KeywordSearchResultDtoFilterInput>>;
  /** Order of the result */
  order?: InputMaybe<IntOperationFilterInput>;
  /** Rating */
  rating?: InputMaybe<RatingDtoFilterInput>;
  /** Reference code of the result. */
  referenceCode?: InputMaybe<StringOperationFilterInput>;
  /** Score */
  score?: InputMaybe<FloatOperationFilterInput>;
  /** Snippet of the result. */
  snippet?: InputMaybe<StringOperationFilterInput>;
  /** Result URI */
  uri?: InputMaybe<UrlOperationFilterInput>;
};

/** Mutations */
export type Mutations = {
  __typename?: 'Mutations';
  /** Clear user history */
  clearHistory: ClearHistoryPayload;
  /** Delete query */
  deleteQuery: DeleteQueryPayload;
  /** Post reply to user message */
  generateReplyToUserMessage: GenerateReplyToUserMessagePayload;
  /** Post reply to chat. If userMessageId points to an existing message, its content is updated and messages below are deleted */
  postReplyToChat: PostReplyToChatPayload;
  /** Perform search */
  search: SearchPayload;
  /** Vote for chat message */
  voteForChatMessage: VoteForChatMessagePayload;
  /** Vote for search result */
  voteForSearchResult: VoteForSearchResultPayload;
};

/** Mutations */
export type MutationsDeleteQueryArgs = {
  input: DeleteQueryInput;
};

/** Mutations */
export type MutationsGenerateReplyToUserMessageArgs = {
  input: GenerateReplyToUserMessageInput;
};

/** Mutations */
export type MutationsPostReplyToChatArgs = {
  input: PostReplyToChatInput;
};

/** Mutations */
export type MutationsSearchArgs = {
  input: SearchInput;
};

/** Mutations */
export type MutationsVoteForChatMessageArgs = {
  input: VoteForChatMessageInput;
};

/** Mutations */
export type MutationsVoteForSearchResultArgs = {
  input: VoteForSearchResultInput;
};

export type NullableOfVoteTypeEnumOperationFilterInput = {
  eq?: InputMaybe<VoteTypeEnum>;
  in?: InputMaybe<Array<InputMaybe<VoteTypeEnum>>>;
  neq?: InputMaybe<VoteTypeEnum>;
  nin?: InputMaybe<Array<InputMaybe<VoteTypeEnum>>>;
};

/** Post reply to chat input */
export type PostReplyToChatInput = {
  /** Search history item id */
  queryId: Scalars['UUID']['input'];
  /** MessageId is the id of the reply message. */
  systemMessageId: Scalars['UUID']['input'];
  /** User message */
  userMessage: Scalars['String']['input'];
  /** UserMessageId is the id of the message that the user sent to the bot. */
  userMessageId: Scalars['UUID']['input'];
};

export type PostReplyToChatPayload = {
  __typename?: 'PostReplyToChatPayload';
  chatTokenStreamDto?: Maybe<ChatTokenStreamDto>;
};

/** A segment of a collection. */
export type QueriesCollectionSegment = {
  __typename?: 'QueriesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<HistoryEntry>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** GraphQl queries */
export type Query = {
  __typename?: 'Query';
  /** Get list of folders */
  folders: Array<FolderDto>;
  /** Get list of queries */
  queries?: Maybe<QueriesCollectionSegment>;
  /** Get single query by id */
  query?: Maybe<HistoryEntry>;
};

/** GraphQl queries */
export type QueryQueriesArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQl queries */
export type QueryQueryArgs = {
  id: Scalars['UUID']['input'];
};

/** Rating */
export type RatingDto = {
  __typename?: 'RatingDto';
  /** Rating */
  rating: Scalars['Int']['output'];
  /** User vote */
  vote?: Maybe<VoteTypeEnum>;
};

/** Rating */
export type RatingDtoFilterInput = {
  and?: InputMaybe<Array<RatingDtoFilterInput>>;
  or?: InputMaybe<Array<RatingDtoFilterInput>>;
  /** Rating */
  rating?: InputMaybe<IntOperationFilterInput>;
  /** User vote */
  vote?: InputMaybe<NullableOfVoteTypeEnumOperationFilterInput>;
};

/** Search filter request */
export type SearchFilterRequestInput = {
  /** Search folders */
  folders?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Max date */
  maxDate?: InputMaybe<Scalars['Date']['input']>;
  /** Min date */
  minDate?: InputMaybe<Scalars['Date']['input']>;
};

export type SearchInput = {
  /** SearchFilters */
  filter?: InputMaybe<SearchFilterRequestInput>;
  /** Query ID */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Query text */
  query: Scalars['String']['input'];
  /** Query type */
  type?: InputMaybe<SearchTypeEnum>;
};

export type SearchPayload = {
  __typename?: 'SearchPayload';
  historyEntry?: Maybe<HistoryEntry>;
};

/** Search type */
export enum SearchTypeEnum {
  /** AI Search */
  AiSearch = 'AI_SEARCH',
  /** Auto */
  Auto = 'AUTO',
  /** Keyword Search */
  KeywordSearch = 'KEYWORD_SEARCH',
}

/** Semantic history entry DTO */
export type SemanticHistoryEntryDto = {
  __typename?: 'SemanticHistoryEntryDto';
  /** List of chat messages */
  chat: Array<ChatMessageDto>;
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Folder IDs */
  folders: Array<Scalars['String']['output']>;
  /** Keyword history entry identifier */
  id: Scalars['UUID']['output'];
  /** Query text */
  query: Scalars['String']['output'];
  /** List of semantic search results */
  searchResults: Array<SemanticSearchResultDto>;
};

/** Semantic history entry DTO */
export type SemanticHistoryEntryDtoChatArgs = {
  where?: InputMaybe<ChatMessageDtoFilterInput>;
};

/** Semantic history entry DTO */
export type SemanticHistoryEntryDtoSearchResultsArgs = {
  where?: InputMaybe<SemanticSearchResultDtoFilterInput>;
};

/** Semantic search result DTO */
export type SemanticSearchResultDto = {
  __typename?: 'SemanticSearchResultDto';
  /** Result answer */
  answer: Scalars['String']['output'];
  /** Text of the result. This is a denormalized field, which is used for full-text search. */
  fullText: Scalars['String']['output'];
  /** Order of the result */
  order: Scalars['Int']['output'];
  /** Rating */
  rating: RatingDto;
  /** Reference code of the result. */
  referenceCode: Scalars['String']['output'];
  /** Score */
  score: Scalars['Float']['output'];
  /** Snippet of the result. */
  snippet: Scalars['String']['output'];
  /** Result URI */
  uri?: Maybe<Scalars['URL']['output']>;
};

/** Semantic search result DTO */
export type SemanticSearchResultDtoFilterInput = {
  and?: InputMaybe<Array<SemanticSearchResultDtoFilterInput>>;
  /** Result answer */
  answer?: InputMaybe<StringOperationFilterInput>;
  /** Text of the result. This is a denormalized field, which is used for full-text search. */
  fullText?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SemanticSearchResultDtoFilterInput>>;
  /** Order of the result */
  order?: InputMaybe<IntOperationFilterInput>;
  /** Rating */
  rating?: InputMaybe<RatingDtoFilterInput>;
  /** Reference code of the result. */
  referenceCode?: InputMaybe<StringOperationFilterInput>;
  /** Score */
  score?: InputMaybe<FloatOperationFilterInput>;
  /** Snippet of the result. */
  snippet?: InputMaybe<StringOperationFilterInput>;
  /** Result URI */
  uri?: InputMaybe<UrlOperationFilterInput>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type UrlOperationFilterInput = {
  eq?: InputMaybe<Scalars['URL']['input']>;
  gt?: InputMaybe<Scalars['URL']['input']>;
  gte?: InputMaybe<Scalars['URL']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['URL']['input']>>>;
  lt?: InputMaybe<Scalars['URL']['input']>;
  lte?: InputMaybe<Scalars['URL']['input']>;
  neq?: InputMaybe<Scalars['URL']['input']>;
  ngt?: InputMaybe<Scalars['URL']['input']>;
  ngte?: InputMaybe<Scalars['URL']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['URL']['input']>>>;
  nlt?: InputMaybe<Scalars['URL']['input']>;
  nlte?: InputMaybe<Scalars['URL']['input']>;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

/** Vote for chat message input */
export type VoteForChatMessageInput = {
  /** Message id */
  messageId: Scalars['UUID']['input'];
  /** Vote type */
  vote?: InputMaybe<VoteTypeEnum>;
};

export type VoteForChatMessagePayload = {
  __typename?: 'VoteForChatMessagePayload';
  ratingDto?: Maybe<RatingDto>;
};

/** Vote for search result input */
export type VoteForSearchResultInput = {
  /** Search result order */
  order: Scalars['Int']['input'];
  /** Query id */
  queryId: Scalars['UUID']['input'];
  /** Vote type */
  vote?: InputMaybe<VoteTypeEnum>;
};

export type VoteForSearchResultPayload = {
  __typename?: 'VoteForSearchResultPayload';
  ratingDto?: Maybe<RatingDto>;
};

/** Vote type */
export enum VoteTypeEnum {
  /** Downvote */
  Downvote = 'DOWNVOTE',
  /** Upvote */
  Upvote = 'UPVOTE',
}

export type ChatMessageFragment = {
  __typename?: 'ChatMessageDto';
  createdAt: any;
  id: any;
  text: string;
  type: ChatMessageType;
  rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
};

export type ChatMessageFragmentVariables = Exact<{ [key: string]: never }>;

export type SearchResultRatingFragment = { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };

export type SearchResultRatingFragmentVariables = Exact<{ [key: string]: never }>;

export type KeywordSearchResultFragment = {
  __typename?: 'KeywordSearchResultDto';
  order: number;
  referenceCode: string;
  score: number;
  snippet: string;
  uri?: any | null;
  rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
};

export type KeywordSearchResultFragmentVariables = Exact<{ [key: string]: never }>;

export type SemanticSearchResultFragment = {
  __typename?: 'SemanticSearchResultDto';
  answer: string;
  fullText: string;
  order: number;
  referenceCode: string;
  score: number;
  snippet: string;
  uri?: any | null;
  rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
};

export type SemanticSearchResultFragmentVariables = Exact<{ [key: string]: never }>;

export type FolderListItemFragment = {
  __typename?: 'FolderDto';
  id: string;
  title: string;
  parentId?: string | null;
  checked: boolean;
};

export type FolderListItemFragmentVariables = Exact<{ [key: string]: never }>;

export type QueryHistoryCollectionFragment = {
  __typename?: 'QueriesCollectionSegment';
  totalCount: number;
  items?: Array<
    | { __typename?: 'KeywordHistoryEntryDto'; createdAt: any; id: any; query: string }
    | { __typename?: 'SemanticHistoryEntryDto'; createdAt: any; id: any; query: string }
  > | null;
  pageInfo: { __typename?: 'CollectionSegmentInfo'; hasNextPage: boolean; hasPreviousPage: boolean };
};

export type QueryHistoryCollectionFragmentVariables = Exact<{ [key: string]: never }>;

export type QueryHistoryItemsFragment = {
  __typename?: 'QueriesCollectionSegment';
  items?: Array<
    | { __typename?: 'KeywordHistoryEntryDto'; createdAt: any; id: any; query: string }
    | { __typename?: 'SemanticHistoryEntryDto'; createdAt: any; id: any; query: string }
  > | null;
};

export type QueryHistoryItemsFragmentVariables = Exact<{ [key: string]: never }>;

export type QueryHistoryCollectionPaginationFragment = {
  __typename?: 'QueriesCollectionSegment';
  totalCount: number;
  pageInfo: { __typename?: 'CollectionSegmentInfo'; hasNextPage: boolean; hasPreviousPage: boolean };
};

export type QueryHistoryCollectionPaginationFragmentVariables = Exact<{ [key: string]: never }>;

export type CollectionSegmentInfoFragment = {
  __typename?: 'CollectionSegmentInfo';
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

export type CollectionSegmentInfoFragmentVariables = Exact<{ [key: string]: never }>;

type QueryHistoryItem_KeywordHistoryEntryDto_Fragment = {
  __typename?: 'KeywordHistoryEntryDto';
  createdAt: any;
  id: any;
  query: string;
};

type QueryHistoryItem_SemanticHistoryEntryDto_Fragment = {
  __typename?: 'SemanticHistoryEntryDto';
  createdAt: any;
  id: any;
  query: string;
};

export type QueryHistoryItemFragment =
  | QueryHistoryItem_KeywordHistoryEntryDto_Fragment
  | QueryHistoryItem_SemanticHistoryEntryDto_Fragment;

export type QueryHistoryItemFragmentVariables = Exact<{ [key: string]: never }>;

export type SendSearchQueryMutationVariables = Exact<{
  input: SearchInput;
  chatFilterInput?: InputMaybe<ChatMessageDtoFilterInput>;
  semanticSerchResultsFilterInput?: InputMaybe<SemanticSearchResultDtoFilterInput>;
  keywordSerchResultsFilterInput?: InputMaybe<KeywordSearchResultDtoFilterInput>;
}>;

export type SendSearchQueryMutation = {
  __typename?: 'Mutations';
  search: {
    __typename?: 'SearchPayload';
    historyEntry?:
      | {
          __typename?: 'KeywordHistoryEntryDto';
          createdAt: any;
          id: any;
          query: string;
          searchResults: Array<{
            __typename?: 'KeywordSearchResultDto';
            order: number;
            referenceCode: string;
            score: number;
            snippet: string;
            uri?: any | null;
            rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
          }>;
        }
      | {
          __typename?: 'SemanticHistoryEntryDto';
          createdAt: any;
          id: any;
          query: string;
          chat: Array<{
            __typename?: 'ChatMessageDto';
            createdAt: any;
            id: any;
            text: string;
            type: ChatMessageType;
            rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
          }>;
          searchResults: Array<{
            __typename?: 'SemanticSearchResultDto';
            answer: string;
            fullText: string;
            order: number;
            referenceCode: string;
            score: number;
            snippet: string;
            uri?: any | null;
            rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
          }>;
        }
      | null;
  };
};

export type SendSearchQueryResultFragment = {
  __typename?: 'SearchPayload';
  historyEntry?:
    | {
        __typename?: 'KeywordHistoryEntryDto';
        createdAt: any;
        id: any;
        query: string;
        searchResults: Array<{
          __typename?: 'KeywordSearchResultDto';
          order: number;
          referenceCode: string;
          score: number;
          snippet: string;
          uri?: any | null;
          rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
        }>;
      }
    | {
        __typename?: 'SemanticHistoryEntryDto';
        createdAt: any;
        id: any;
        query: string;
        chat: Array<{
          __typename?: 'ChatMessageDto';
          createdAt: any;
          id: any;
          text: string;
          type: ChatMessageType;
          rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
        }>;
        searchResults: Array<{
          __typename?: 'SemanticSearchResultDto';
          answer: string;
          fullText: string;
          order: number;
          referenceCode: string;
          score: number;
          snippet: string;
          uri?: any | null;
          rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
        }>;
      }
    | null;
};

export type SendSearchQueryResultFragmentVariables = Exact<{ [key: string]: never }>;

export type PostReplyToChatMutationVariables = Exact<{
  input: PostReplyToChatInput;
}>;

export type PostReplyToChatMutation = {
  __typename?: 'Mutations';
  postReplyToChat: {
    __typename?: 'PostReplyToChatPayload';
    chatTokenStreamDto?: {
      __typename?: 'ChatTokenStreamDto';
      id: any;
      tokens: Array<{ __typename?: 'ChatTokenDto'; token: string }>;
    } | null;
  };
};

export type GenerateReplyToUserMessageMutationVariables = Exact<{
  input: GenerateReplyToUserMessageInput;
}>;

export type GenerateReplyToUserMessageMutation = {
  __typename?: 'Mutations';
  generateReplyToUserMessage: {
    __typename?: 'GenerateReplyToUserMessagePayload';
    chatTokenStreamDto?: {
      __typename?: 'ChatTokenStreamDto';
      id: any;
      tokens: Array<{ __typename?: 'ChatTokenDto'; token: string }>;
    } | null;
  };
};

export type ChatReplyStreamItemFragment = {
  __typename?: 'ChatTokenStreamDto';
  id: any;
  tokens: Array<{ __typename?: 'ChatTokenDto'; token: string }>;
};

export type ChatReplyStreamItemFragmentVariables = Exact<{ [key: string]: never }>;

export type ChatReplyStreamItemChunkFragment = { __typename?: 'ChatTokenDto'; token: string };

export type ChatReplyStreamItemChunkFragmentVariables = Exact<{ [key: string]: never }>;

export type VoteForChatMessageMutationVariables = Exact<{
  input: VoteForChatMessageInput;
}>;

export type VoteForChatMessageMutation = {
  __typename?: 'Mutations';
  voteForChatMessage: {
    __typename?: 'VoteForChatMessagePayload';
    ratingDto?: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null } | null;
  };
};

export type VoteForSearchResultMutationVariables = Exact<{
  input: VoteForSearchResultInput;
}>;

export type VoteForSearchResultMutation = {
  __typename?: 'Mutations';
  voteForSearchResult: {
    __typename?: 'VoteForSearchResultPayload';
    ratingDto?: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null } | null;
  };
};

export type DeleteQueryMutationVariables = Exact<{
  input: DeleteQueryInput;
}>;

export type DeleteQueryMutation = {
  __typename?: 'Mutations';
  deleteQuery: { __typename?: 'DeleteQueryPayload'; boolean?: boolean | null };
};

export type ClearQueryHistoryMutationVariables = Exact<{ [key: string]: never }>;

export type ClearQueryHistoryMutation = {
  __typename?: 'Mutations';
  clearHistory: { __typename?: 'ClearHistoryPayload'; boolean?: boolean | null };
};

export type GetFoldersListQueryVariables = Exact<{ [key: string]: never }>;

export type GetFoldersListQuery = {
  __typename?: 'Query';
  folders: Array<{ __typename?: 'FolderDto'; id: string; title: string; parentId?: string | null; checked: boolean }>;
};

export type GetQueryHistoryQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetQueryHistoryQuery = {
  __typename?: 'Query';
  queries?: {
    __typename?: 'QueriesCollectionSegment';
    totalCount: number;
    items?: Array<
      | { __typename?: 'KeywordHistoryEntryDto'; createdAt: any; id: any; query: string }
      | { __typename?: 'SemanticHistoryEntryDto'; createdAt: any; id: any; query: string }
    > | null;
    pageInfo: { __typename?: 'CollectionSegmentInfo'; hasNextPage: boolean; hasPreviousPage: boolean };
  } | null;
};

export type GetSelectedQueryHistoryShortItemQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetSelectedQueryHistoryShortItemQuery = {
  __typename?: 'Query';
  query?:
    | { __typename?: 'KeywordHistoryEntryDto'; createdAt: any; id: any; query: string; folders: Array<string> }
    | { __typename?: 'SemanticHistoryEntryDto'; createdAt: any; id: any; query: string; folders: Array<string> }
    | null;
};

type SelectedQueryHistoryShortItem_KeywordHistoryEntryDto_Fragment = {
  __typename?: 'KeywordHistoryEntryDto';
  createdAt: any;
  id: any;
  query: string;
  folders: Array<string>;
};

type SelectedQueryHistoryShortItem_SemanticHistoryEntryDto_Fragment = {
  __typename?: 'SemanticHistoryEntryDto';
  createdAt: any;
  id: any;
  query: string;
  folders: Array<string>;
};

export type SelectedQueryHistoryShortItemFragment =
  | SelectedQueryHistoryShortItem_KeywordHistoryEntryDto_Fragment
  | SelectedQueryHistoryShortItem_SemanticHistoryEntryDto_Fragment;

export type SelectedQueryHistoryShortItemFragmentVariables = Exact<{ [key: string]: never }>;

export type GetSelectedSemanticQueryHistoryItemChatQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  chatFilterInput?: InputMaybe<ChatMessageDtoFilterInput>;
}>;

export type GetSelectedSemanticQueryHistoryItemChatQuery = {
  __typename?: 'Query';
  query?:
    | { __typename?: 'KeywordHistoryEntryDto' }
    | {
        __typename?: 'SemanticHistoryEntryDto';
        id: any;
        query: string;
        chat: Array<{
          __typename?: 'ChatMessageDto';
          createdAt: any;
          id: any;
          text: string;
          type: ChatMessageType;
          rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
        }>;
      }
    | null;
};

type SelectedSemanticQueryHistoryItemChat_KeywordHistoryEntryDto_Fragment = { __typename?: 'KeywordHistoryEntryDto' };

type SelectedSemanticQueryHistoryItemChat_SemanticHistoryEntryDto_Fragment = {
  __typename?: 'SemanticHistoryEntryDto';
  id: any;
  query: string;
  chat: Array<{
    __typename?: 'ChatMessageDto';
    createdAt: any;
    id: any;
    text: string;
    type: ChatMessageType;
    rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
  }>;
};

export type SelectedSemanticQueryHistoryItemChatFragment =
  | SelectedSemanticQueryHistoryItemChat_KeywordHistoryEntryDto_Fragment
  | SelectedSemanticQueryHistoryItemChat_SemanticHistoryEntryDto_Fragment;

export type SelectedSemanticQueryHistoryItemChatFragmentVariables = Exact<{ [key: string]: never }>;

export type GetSelectedQueryHistoryItemSearchResultsQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  semanticSearchResultsFilterInput?: InputMaybe<SemanticSearchResultDtoFilterInput>;
  keywordSearchResultsFilterInput?: InputMaybe<KeywordSearchResultDtoFilterInput>;
}>;

export type GetSelectedQueryHistoryItemSearchResultsQuery = {
  __typename?: 'Query';
  query?:
    | {
        __typename?: 'KeywordHistoryEntryDto';
        id: any;
        query: string;
        searchResults: Array<{
          __typename?: 'KeywordSearchResultDto';
          order: number;
          referenceCode: string;
          score: number;
          snippet: string;
          uri?: any | null;
          rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
        }>;
      }
    | {
        __typename?: 'SemanticHistoryEntryDto';
        id: any;
        query: string;
        searchResults: Array<{
          __typename?: 'SemanticSearchResultDto';
          answer: string;
          fullText: string;
          order: number;
          referenceCode: string;
          score: number;
          snippet: string;
          uri?: any | null;
          rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
        }>;
      }
    | null;
};

type SelectedQueryHistoryItemSearchResults_KeywordHistoryEntryDto_Fragment = {
  __typename?: 'KeywordHistoryEntryDto';
  id: any;
  query: string;
  searchResults: Array<{
    __typename?: 'KeywordSearchResultDto';
    order: number;
    referenceCode: string;
    score: number;
    snippet: string;
    uri?: any | null;
    rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
  }>;
};

type SelectedQueryHistoryItemSearchResults_SemanticHistoryEntryDto_Fragment = {
  __typename?: 'SemanticHistoryEntryDto';
  id: any;
  query: string;
  searchResults: Array<{
    __typename?: 'SemanticSearchResultDto';
    answer: string;
    fullText: string;
    order: number;
    referenceCode: string;
    score: number;
    snippet: string;
    uri?: any | null;
    rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
  }>;
};

export type SelectedQueryHistoryItemSearchResultsFragment =
  | SelectedQueryHistoryItemSearchResults_KeywordHistoryEntryDto_Fragment
  | SelectedQueryHistoryItemSearchResults_SemanticHistoryEntryDto_Fragment;

export type SelectedQueryHistoryItemSearchResultsFragmentVariables = Exact<{ [key: string]: never }>;

export type GetSelectedQueryHistoryItemQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  chatFilterInput?: InputMaybe<ChatMessageDtoFilterInput>;
  semanticSearchResultsFilterInput?: InputMaybe<SemanticSearchResultDtoFilterInput>;
  keywordSearchResultsFilterInput?: InputMaybe<KeywordSearchResultDtoFilterInput>;
}>;

export type GetSelectedQueryHistoryItemQuery = {
  __typename?: 'Query';
  query?:
    | {
        __typename?: 'KeywordHistoryEntryDto';
        createdAt: any;
        id: any;
        query: string;
        folders: Array<string>;
        searchResults: Array<{
          __typename?: 'KeywordSearchResultDto';
          order: number;
          referenceCode: string;
          score: number;
          snippet: string;
          uri?: any | null;
          rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
        }>;
      }
    | {
        __typename?: 'SemanticHistoryEntryDto';
        createdAt: any;
        id: any;
        query: string;
        folders: Array<string>;
        chat: Array<{
          __typename?: 'ChatMessageDto';
          createdAt: any;
          id: any;
          text: string;
          type: ChatMessageType;
          rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
        }>;
        searchResults: Array<{
          __typename?: 'SemanticSearchResultDto';
          answer: string;
          fullText: string;
          order: number;
          referenceCode: string;
          score: number;
          snippet: string;
          uri?: any | null;
          rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
        }>;
      }
    | null;
};

type SelectedQueryHistoryItem_KeywordHistoryEntryDto_Fragment = {
  __typename?: 'KeywordHistoryEntryDto';
  createdAt: any;
  id: any;
  query: string;
  folders: Array<string>;
  searchResults: Array<{
    __typename?: 'KeywordSearchResultDto';
    order: number;
    referenceCode: string;
    score: number;
    snippet: string;
    uri?: any | null;
    rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
  }>;
};

type SelectedQueryHistoryItem_SemanticHistoryEntryDto_Fragment = {
  __typename?: 'SemanticHistoryEntryDto';
  createdAt: any;
  id: any;
  query: string;
  folders: Array<string>;
  chat: Array<{
    __typename?: 'ChatMessageDto';
    createdAt: any;
    id: any;
    text: string;
    type: ChatMessageType;
    rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
  }>;
  searchResults: Array<{
    __typename?: 'SemanticSearchResultDto';
    answer: string;
    fullText: string;
    order: number;
    referenceCode: string;
    score: number;
    snippet: string;
    uri?: any | null;
    rating: { __typename?: 'RatingDto'; rating: number; vote?: VoteTypeEnum | null };
  }>;
};

export type SelectedQueryHistoryItemFragment =
  | SelectedQueryHistoryItem_KeywordHistoryEntryDto_Fragment
  | SelectedQueryHistoryItem_SemanticHistoryEntryDto_Fragment;

export type SelectedQueryHistoryItemFragmentVariables = Exact<{ [key: string]: never }>;

export const FolderListItemFragmentDoc = gql`
  fragment FolderListItem on FolderDto {
    id
    title
    parentId
    checked
  }
`;
export const QueryHistoryItemFragmentDoc = gql`
  fragment QueryHistoryItem on HistoryEntry {
    ... on KeywordHistoryEntryDto {
      createdAt
      id
      query
    }
    ... on SemanticHistoryEntryDto {
      createdAt
      id
      query
    }
  }
`;
export const QueryHistoryItemsFragmentDoc = gql`
  fragment QueryHistoryItems on QueriesCollectionSegment {
    items {
      ...QueryHistoryItem
    }
  }
`;
export const CollectionSegmentInfoFragmentDoc = gql`
  fragment CollectionSegmentInfo on CollectionSegmentInfo {
    hasNextPage
    hasPreviousPage
  }
`;
export const QueryHistoryCollectionPaginationFragmentDoc = gql`
  fragment QueryHistoryCollectionPagination on QueriesCollectionSegment {
    pageInfo {
      ...CollectionSegmentInfo
    }
    totalCount
  }
`;
export const QueryHistoryCollectionFragmentDoc = gql`
  fragment QueryHistoryCollection on QueriesCollectionSegment {
    ...QueryHistoryItems
    ...QueryHistoryCollectionPagination
  }
`;
export const SearchResultRatingFragmentDoc = gql`
  fragment SearchResultRating on RatingDto {
    rating
    vote
  }
`;
export const KeywordSearchResultFragmentDoc = gql`
  fragment KeywordSearchResult on KeywordSearchResultDto {
    order
    rating {
      ...SearchResultRating
    }
    referenceCode
    score
    snippet
    uri
  }
`;
export const ChatMessageFragmentDoc = gql`
  fragment ChatMessage on ChatMessageDto {
    createdAt
    id
    rating {
      ...SearchResultRating
    }
    text
    type
  }
`;
export const SemanticSearchResultFragmentDoc = gql`
  fragment SemanticSearchResult on SemanticSearchResultDto {
    answer
    fullText
    order
    rating {
      ...SearchResultRating
    }
    referenceCode
    score
    snippet
    uri
  }
`;
export const SendSearchQueryResultFragmentDoc = gql`
  fragment SendSearchQueryResult on SearchPayload {
    historyEntry {
      ... on KeywordHistoryEntryDto {
        createdAt
        id
        query
        searchResults(where: $keywordSerchResultsFilterInput) {
          ...KeywordSearchResult
        }
      }
      ... on SemanticHistoryEntryDto {
        chat(where: $chatFilterInput) {
          ...ChatMessage
        }
        createdAt
        id
        query
        searchResults(where: $semanticSerchResultsFilterInput) {
          ...SemanticSearchResult
        }
      }
    }
  }
`;
export const ChatReplyStreamItemChunkFragmentDoc = gql`
  fragment ChatReplyStreamItemChunk on ChatTokenDto {
    token
  }
`;
export const ChatReplyStreamItemFragmentDoc = gql`
  fragment ChatReplyStreamItem on ChatTokenStreamDto {
    id
    tokens @stream {
      ...ChatReplyStreamItemChunk
    }
  }
`;
export const SelectedQueryHistoryShortItemFragmentDoc = gql`
  fragment SelectedQueryHistoryShortItem on HistoryEntry {
    ... on KeywordHistoryEntryDto {
      createdAt
      id
      query
      folders
    }
    ... on SemanticHistoryEntryDto {
      createdAt
      id
      query
      folders
    }
  }
`;
export const SelectedSemanticQueryHistoryItemChatFragmentDoc = gql`
  fragment SelectedSemanticQueryHistoryItemChat on HistoryEntry {
    ... on SemanticHistoryEntryDto {
      id
      query
      chat(where: $chatFilterInput) {
        ...ChatMessage
      }
    }
  }
`;
export const SelectedQueryHistoryItemSearchResultsFragmentDoc = gql`
  fragment SelectedQueryHistoryItemSearchResults on HistoryEntry {
    ... on KeywordHistoryEntryDto {
      id
      query
      searchResults(where: $keywordSearchResultsFilterInput) {
        ...KeywordSearchResult
      }
    }
    ... on SemanticHistoryEntryDto {
      id
      query
      searchResults(where: $semanticSearchResultsFilterInput) {
        ...SemanticSearchResult
      }
    }
  }
`;
export const SelectedQueryHistoryItemFragmentDoc = gql`
  fragment SelectedQueryHistoryItem on HistoryEntry {
    ... on KeywordHistoryEntryDto {
      createdAt
      id
      query
      folders
      searchResults(where: $keywordSearchResultsFilterInput) {
        ...KeywordSearchResult
      }
    }
    ... on SemanticHistoryEntryDto {
      chat(where: $chatFilterInput) {
        ...ChatMessage
      }
      createdAt
      id
      query
      folders
      searchResults(where: $semanticSearchResultsFilterInput) {
        ...SemanticSearchResult
      }
    }
  }
`;
export const SendSearchQueryDocument = gql`
  mutation SendSearchQuery(
    $input: SearchInput!
    $chatFilterInput: ChatMessageDtoFilterInput
    $semanticSerchResultsFilterInput: SemanticSearchResultDtoFilterInput
    $keywordSerchResultsFilterInput: KeywordSearchResultDtoFilterInput
  ) {
    search(input: $input) {
      ...SendSearchQueryResult
    }
  }
  ${SendSearchQueryResultFragmentDoc}
  ${KeywordSearchResultFragmentDoc}
  ${SearchResultRatingFragmentDoc}
  ${ChatMessageFragmentDoc}
  ${SemanticSearchResultFragmentDoc}
`;
export type SendSearchQueryMutationFn = Apollo.MutationFunction<
  SendSearchQueryMutation,
  SendSearchQueryMutationVariables
>;

/**
 * __useSendSearchQueryMutation__
 *
 * To run a mutation, you first call `useSendSearchQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSearchQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSearchQueryMutation, { data, loading, error }] = useSendSearchQueryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      chatFilterInput: // value for 'chatFilterInput'
 *      semanticSerchResultsFilterInput: // value for 'semanticSerchResultsFilterInput'
 *      keywordSerchResultsFilterInput: // value for 'keywordSerchResultsFilterInput'
 *   },
 * });
 */
export function useSendSearchQueryMutation(
  baseOptions?: Apollo.MutationHookOptions<SendSearchQueryMutation, SendSearchQueryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendSearchQueryMutation, SendSearchQueryMutationVariables>(
    SendSearchQueryDocument,
    options,
  );
}
export type SendSearchQueryMutationHookResult = ReturnType<typeof useSendSearchQueryMutation>;
export type SendSearchQueryMutationResult = Apollo.MutationResult<SendSearchQueryMutation>;
export type SendSearchQueryMutationOptions = Apollo.BaseMutationOptions<
  SendSearchQueryMutation,
  SendSearchQueryMutationVariables
>;
export const PostReplyToChatDocument = gql`
  mutation PostReplyToChat($input: PostReplyToChatInput!) {
    postReplyToChat(input: $input) {
      chatTokenStreamDto {
        ...ChatReplyStreamItem
      }
    }
  }
  ${ChatReplyStreamItemFragmentDoc}
  ${ChatReplyStreamItemChunkFragmentDoc}
`;
export type PostReplyToChatMutationFn = Apollo.MutationFunction<
  PostReplyToChatMutation,
  PostReplyToChatMutationVariables
>;

/**
 * __usePostReplyToChatMutation__
 *
 * To run a mutation, you first call `usePostReplyToChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostReplyToChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postReplyToChatMutation, { data, loading, error }] = usePostReplyToChatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostReplyToChatMutation(
  baseOptions?: Apollo.MutationHookOptions<PostReplyToChatMutation, PostReplyToChatMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PostReplyToChatMutation, PostReplyToChatMutationVariables>(
    PostReplyToChatDocument,
    options,
  );
}
export type PostReplyToChatMutationHookResult = ReturnType<typeof usePostReplyToChatMutation>;
export type PostReplyToChatMutationResult = Apollo.MutationResult<PostReplyToChatMutation>;
export type PostReplyToChatMutationOptions = Apollo.BaseMutationOptions<
  PostReplyToChatMutation,
  PostReplyToChatMutationVariables
>;
export const GenerateReplyToUserMessageDocument = gql`
  mutation GenerateReplyToUserMessage($input: GenerateReplyToUserMessageInput!) {
    generateReplyToUserMessage(input: $input) {
      chatTokenStreamDto {
        ...ChatReplyStreamItem
      }
    }
  }
  ${ChatReplyStreamItemFragmentDoc}
  ${ChatReplyStreamItemChunkFragmentDoc}
`;
export type GenerateReplyToUserMessageMutationFn = Apollo.MutationFunction<
  GenerateReplyToUserMessageMutation,
  GenerateReplyToUserMessageMutationVariables
>;

/**
 * __useGenerateReplyToUserMessageMutation__
 *
 * To run a mutation, you first call `useGenerateReplyToUserMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateReplyToUserMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateReplyToUserMessageMutation, { data, loading, error }] = useGenerateReplyToUserMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateReplyToUserMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateReplyToUserMessageMutation,
    GenerateReplyToUserMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateReplyToUserMessageMutation, GenerateReplyToUserMessageMutationVariables>(
    GenerateReplyToUserMessageDocument,
    options,
  );
}
export type GenerateReplyToUserMessageMutationHookResult = ReturnType<typeof useGenerateReplyToUserMessageMutation>;
export type GenerateReplyToUserMessageMutationResult = Apollo.MutationResult<GenerateReplyToUserMessageMutation>;
export type GenerateReplyToUserMessageMutationOptions = Apollo.BaseMutationOptions<
  GenerateReplyToUserMessageMutation,
  GenerateReplyToUserMessageMutationVariables
>;
export const VoteForChatMessageDocument = gql`
  mutation VoteForChatMessage($input: VoteForChatMessageInput!) {
    voteForChatMessage(input: $input) {
      ratingDto {
        ...SearchResultRating
      }
    }
  }
  ${SearchResultRatingFragmentDoc}
`;
export type VoteForChatMessageMutationFn = Apollo.MutationFunction<
  VoteForChatMessageMutation,
  VoteForChatMessageMutationVariables
>;

/**
 * __useVoteForChatMessageMutation__
 *
 * To run a mutation, you first call `useVoteForChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteForChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteForChatMessageMutation, { data, loading, error }] = useVoteForChatMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoteForChatMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<VoteForChatMessageMutation, VoteForChatMessageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VoteForChatMessageMutation, VoteForChatMessageMutationVariables>(
    VoteForChatMessageDocument,
    options,
  );
}
export type VoteForChatMessageMutationHookResult = ReturnType<typeof useVoteForChatMessageMutation>;
export type VoteForChatMessageMutationResult = Apollo.MutationResult<VoteForChatMessageMutation>;
export type VoteForChatMessageMutationOptions = Apollo.BaseMutationOptions<
  VoteForChatMessageMutation,
  VoteForChatMessageMutationVariables
>;
export const VoteForSearchResultDocument = gql`
  mutation VoteForSearchResult($input: VoteForSearchResultInput!) {
    voteForSearchResult(input: $input) {
      ratingDto {
        ...SearchResultRating
      }
    }
  }
  ${SearchResultRatingFragmentDoc}
`;
export type VoteForSearchResultMutationFn = Apollo.MutationFunction<
  VoteForSearchResultMutation,
  VoteForSearchResultMutationVariables
>;

/**
 * __useVoteForSearchResultMutation__
 *
 * To run a mutation, you first call `useVoteForSearchResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteForSearchResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteForSearchResultMutation, { data, loading, error }] = useVoteForSearchResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoteForSearchResultMutation(
  baseOptions?: Apollo.MutationHookOptions<VoteForSearchResultMutation, VoteForSearchResultMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VoteForSearchResultMutation, VoteForSearchResultMutationVariables>(
    VoteForSearchResultDocument,
    options,
  );
}
export type VoteForSearchResultMutationHookResult = ReturnType<typeof useVoteForSearchResultMutation>;
export type VoteForSearchResultMutationResult = Apollo.MutationResult<VoteForSearchResultMutation>;
export type VoteForSearchResultMutationOptions = Apollo.BaseMutationOptions<
  VoteForSearchResultMutation,
  VoteForSearchResultMutationVariables
>;
export const DeleteQueryDocument = gql`
  mutation DeleteQuery($input: DeleteQueryInput!) {
    deleteQuery(input: $input) {
      boolean
    }
  }
`;
export type DeleteQueryMutationFn = Apollo.MutationFunction<DeleteQueryMutation, DeleteQueryMutationVariables>;

/**
 * __useDeleteQueryMutation__
 *
 * To run a mutation, you first call `useDeleteQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQueryMutation, { data, loading, error }] = useDeleteQueryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteQueryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteQueryMutation, DeleteQueryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteQueryMutation, DeleteQueryMutationVariables>(DeleteQueryDocument, options);
}
export type DeleteQueryMutationHookResult = ReturnType<typeof useDeleteQueryMutation>;
export type DeleteQueryMutationResult = Apollo.MutationResult<DeleteQueryMutation>;
export type DeleteQueryMutationOptions = Apollo.BaseMutationOptions<DeleteQueryMutation, DeleteQueryMutationVariables>;
export const ClearQueryHistoryDocument = gql`
  mutation ClearQueryHistory {
    clearHistory {
      boolean
    }
  }
`;
export type ClearQueryHistoryMutationFn = Apollo.MutationFunction<
  ClearQueryHistoryMutation,
  ClearQueryHistoryMutationVariables
>;

/**
 * __useClearQueryHistoryMutation__
 *
 * To run a mutation, you first call `useClearQueryHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearQueryHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearQueryHistoryMutation, { data, loading, error }] = useClearQueryHistoryMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearQueryHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<ClearQueryHistoryMutation, ClearQueryHistoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClearQueryHistoryMutation, ClearQueryHistoryMutationVariables>(
    ClearQueryHistoryDocument,
    options,
  );
}
export type ClearQueryHistoryMutationHookResult = ReturnType<typeof useClearQueryHistoryMutation>;
export type ClearQueryHistoryMutationResult = Apollo.MutationResult<ClearQueryHistoryMutation>;
export type ClearQueryHistoryMutationOptions = Apollo.BaseMutationOptions<
  ClearQueryHistoryMutation,
  ClearQueryHistoryMutationVariables
>;
export const GetFoldersListDocument = gql`
  query GetFoldersList {
    folders {
      ...FolderListItem
    }
  }
  ${FolderListItemFragmentDoc}
`;

/**
 * __useGetFoldersListQuery__
 *
 * To run a query within a React component, call `useGetFoldersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFoldersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFoldersListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFoldersListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFoldersListQuery, GetFoldersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFoldersListQuery, GetFoldersListQueryVariables>(GetFoldersListDocument, options);
}
export function useGetFoldersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFoldersListQuery, GetFoldersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFoldersListQuery, GetFoldersListQueryVariables>(GetFoldersListDocument, options);
}
export function useGetFoldersListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetFoldersListQuery, GetFoldersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFoldersListQuery, GetFoldersListQueryVariables>(GetFoldersListDocument, options);
}
export type GetFoldersListQueryHookResult = ReturnType<typeof useGetFoldersListQuery>;
export type GetFoldersListLazyQueryHookResult = ReturnType<typeof useGetFoldersListLazyQuery>;
export type GetFoldersListSuspenseQueryHookResult = ReturnType<typeof useGetFoldersListSuspenseQuery>;
export type GetFoldersListQueryResult = Apollo.QueryResult<GetFoldersListQuery, GetFoldersListQueryVariables>;
export const GetQueryHistoryDocument = gql`
  query GetQueryHistory($search: String, $skip: Int, $take: Int) {
    queries(search: $search, skip: $skip, take: $take) {
      ...QueryHistoryCollection
    }
  }
  ${QueryHistoryCollectionFragmentDoc}
  ${QueryHistoryItemsFragmentDoc}
  ${QueryHistoryItemFragmentDoc}
  ${QueryHistoryCollectionPaginationFragmentDoc}
  ${CollectionSegmentInfoFragmentDoc}
`;

/**
 * __useGetQueryHistoryQuery__
 *
 * To run a query within a React component, call `useGetQueryHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQueryHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQueryHistoryQuery({
 *   variables: {
 *      search: // value for 'search'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetQueryHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<GetQueryHistoryQuery, GetQueryHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQueryHistoryQuery, GetQueryHistoryQueryVariables>(GetQueryHistoryDocument, options);
}
export function useGetQueryHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetQueryHistoryQuery, GetQueryHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQueryHistoryQuery, GetQueryHistoryQueryVariables>(GetQueryHistoryDocument, options);
}
export function useGetQueryHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetQueryHistoryQuery, GetQueryHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetQueryHistoryQuery, GetQueryHistoryQueryVariables>(GetQueryHistoryDocument, options);
}
export type GetQueryHistoryQueryHookResult = ReturnType<typeof useGetQueryHistoryQuery>;
export type GetQueryHistoryLazyQueryHookResult = ReturnType<typeof useGetQueryHistoryLazyQuery>;
export type GetQueryHistorySuspenseQueryHookResult = ReturnType<typeof useGetQueryHistorySuspenseQuery>;
export type GetQueryHistoryQueryResult = Apollo.QueryResult<GetQueryHistoryQuery, GetQueryHistoryQueryVariables>;
export const GetSelectedQueryHistoryShortItemDocument = gql`
  query GetSelectedQueryHistoryShortItem($id: UUID!) {
    query(id: $id) {
      ...SelectedQueryHistoryShortItem
    }
  }
  ${SelectedQueryHistoryShortItemFragmentDoc}
`;

/**
 * __useGetSelectedQueryHistoryShortItemQuery__
 *
 * To run a query within a React component, call `useGetSelectedQueryHistoryShortItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedQueryHistoryShortItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedQueryHistoryShortItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSelectedQueryHistoryShortItemQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSelectedQueryHistoryShortItemQuery,
    GetSelectedQueryHistoryShortItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSelectedQueryHistoryShortItemQuery, GetSelectedQueryHistoryShortItemQueryVariables>(
    GetSelectedQueryHistoryShortItemDocument,
    options,
  );
}
export function useGetSelectedQueryHistoryShortItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSelectedQueryHistoryShortItemQuery,
    GetSelectedQueryHistoryShortItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSelectedQueryHistoryShortItemQuery, GetSelectedQueryHistoryShortItemQueryVariables>(
    GetSelectedQueryHistoryShortItemDocument,
    options,
  );
}
export function useGetSelectedQueryHistoryShortItemSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSelectedQueryHistoryShortItemQuery,
    GetSelectedQueryHistoryShortItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSelectedQueryHistoryShortItemQuery, GetSelectedQueryHistoryShortItemQueryVariables>(
    GetSelectedQueryHistoryShortItemDocument,
    options,
  );
}
export type GetSelectedQueryHistoryShortItemQueryHookResult = ReturnType<
  typeof useGetSelectedQueryHistoryShortItemQuery
>;
export type GetSelectedQueryHistoryShortItemLazyQueryHookResult = ReturnType<
  typeof useGetSelectedQueryHistoryShortItemLazyQuery
>;
export type GetSelectedQueryHistoryShortItemSuspenseQueryHookResult = ReturnType<
  typeof useGetSelectedQueryHistoryShortItemSuspenseQuery
>;
export type GetSelectedQueryHistoryShortItemQueryResult = Apollo.QueryResult<
  GetSelectedQueryHistoryShortItemQuery,
  GetSelectedQueryHistoryShortItemQueryVariables
>;
export const GetSelectedSemanticQueryHistoryItemChatDocument = gql`
  query GetSelectedSemanticQueryHistoryItemChat($id: UUID!, $chatFilterInput: ChatMessageDtoFilterInput) {
    query(id: $id) {
      ...SelectedSemanticQueryHistoryItemChat
    }
  }
  ${SelectedSemanticQueryHistoryItemChatFragmentDoc}
  ${ChatMessageFragmentDoc}
  ${SearchResultRatingFragmentDoc}
`;

/**
 * __useGetSelectedSemanticQueryHistoryItemChatQuery__
 *
 * To run a query within a React component, call `useGetSelectedSemanticQueryHistoryItemChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedSemanticQueryHistoryItemChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedSemanticQueryHistoryItemChatQuery({
 *   variables: {
 *      id: // value for 'id'
 *      chatFilterInput: // value for 'chatFilterInput'
 *   },
 * });
 */
export function useGetSelectedSemanticQueryHistoryItemChatQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSelectedSemanticQueryHistoryItemChatQuery,
    GetSelectedSemanticQueryHistoryItemChatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSelectedSemanticQueryHistoryItemChatQuery,
    GetSelectedSemanticQueryHistoryItemChatQueryVariables
  >(GetSelectedSemanticQueryHistoryItemChatDocument, options);
}
export function useGetSelectedSemanticQueryHistoryItemChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSelectedSemanticQueryHistoryItemChatQuery,
    GetSelectedSemanticQueryHistoryItemChatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSelectedSemanticQueryHistoryItemChatQuery,
    GetSelectedSemanticQueryHistoryItemChatQueryVariables
  >(GetSelectedSemanticQueryHistoryItemChatDocument, options);
}
export function useGetSelectedSemanticQueryHistoryItemChatSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSelectedSemanticQueryHistoryItemChatQuery,
    GetSelectedSemanticQueryHistoryItemChatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSelectedSemanticQueryHistoryItemChatQuery,
    GetSelectedSemanticQueryHistoryItemChatQueryVariables
  >(GetSelectedSemanticQueryHistoryItemChatDocument, options);
}
export type GetSelectedSemanticQueryHistoryItemChatQueryHookResult = ReturnType<
  typeof useGetSelectedSemanticQueryHistoryItemChatQuery
>;
export type GetSelectedSemanticQueryHistoryItemChatLazyQueryHookResult = ReturnType<
  typeof useGetSelectedSemanticQueryHistoryItemChatLazyQuery
>;
export type GetSelectedSemanticQueryHistoryItemChatSuspenseQueryHookResult = ReturnType<
  typeof useGetSelectedSemanticQueryHistoryItemChatSuspenseQuery
>;
export type GetSelectedSemanticQueryHistoryItemChatQueryResult = Apollo.QueryResult<
  GetSelectedSemanticQueryHistoryItemChatQuery,
  GetSelectedSemanticQueryHistoryItemChatQueryVariables
>;
export const GetSelectedQueryHistoryItemSearchResultsDocument = gql`
  query GetSelectedQueryHistoryItemSearchResults(
    $id: UUID!
    $semanticSearchResultsFilterInput: SemanticSearchResultDtoFilterInput
    $keywordSearchResultsFilterInput: KeywordSearchResultDtoFilterInput
  ) {
    query(id: $id) {
      ...SelectedQueryHistoryItemSearchResults
    }
  }
  ${SelectedQueryHistoryItemSearchResultsFragmentDoc}
  ${KeywordSearchResultFragmentDoc}
  ${SearchResultRatingFragmentDoc}
  ${SemanticSearchResultFragmentDoc}
`;

/**
 * __useGetSelectedQueryHistoryItemSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetSelectedQueryHistoryItemSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedQueryHistoryItemSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedQueryHistoryItemSearchResultsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      semanticSearchResultsFilterInput: // value for 'semanticSearchResultsFilterInput'
 *      keywordSearchResultsFilterInput: // value for 'keywordSearchResultsFilterInput'
 *   },
 * });
 */
export function useGetSelectedQueryHistoryItemSearchResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSelectedQueryHistoryItemSearchResultsQuery,
    GetSelectedQueryHistoryItemSearchResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSelectedQueryHistoryItemSearchResultsQuery,
    GetSelectedQueryHistoryItemSearchResultsQueryVariables
  >(GetSelectedQueryHistoryItemSearchResultsDocument, options);
}
export function useGetSelectedQueryHistoryItemSearchResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSelectedQueryHistoryItemSearchResultsQuery,
    GetSelectedQueryHistoryItemSearchResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSelectedQueryHistoryItemSearchResultsQuery,
    GetSelectedQueryHistoryItemSearchResultsQueryVariables
  >(GetSelectedQueryHistoryItemSearchResultsDocument, options);
}
export function useGetSelectedQueryHistoryItemSearchResultsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSelectedQueryHistoryItemSearchResultsQuery,
    GetSelectedQueryHistoryItemSearchResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSelectedQueryHistoryItemSearchResultsQuery,
    GetSelectedQueryHistoryItemSearchResultsQueryVariables
  >(GetSelectedQueryHistoryItemSearchResultsDocument, options);
}
export type GetSelectedQueryHistoryItemSearchResultsQueryHookResult = ReturnType<
  typeof useGetSelectedQueryHistoryItemSearchResultsQuery
>;
export type GetSelectedQueryHistoryItemSearchResultsLazyQueryHookResult = ReturnType<
  typeof useGetSelectedQueryHistoryItemSearchResultsLazyQuery
>;
export type GetSelectedQueryHistoryItemSearchResultsSuspenseQueryHookResult = ReturnType<
  typeof useGetSelectedQueryHistoryItemSearchResultsSuspenseQuery
>;
export type GetSelectedQueryHistoryItemSearchResultsQueryResult = Apollo.QueryResult<
  GetSelectedQueryHistoryItemSearchResultsQuery,
  GetSelectedQueryHistoryItemSearchResultsQueryVariables
>;
export const GetSelectedQueryHistoryItemDocument = gql`
  query GetSelectedQueryHistoryItem(
    $id: UUID!
    $chatFilterInput: ChatMessageDtoFilterInput
    $semanticSearchResultsFilterInput: SemanticSearchResultDtoFilterInput
    $keywordSearchResultsFilterInput: KeywordSearchResultDtoFilterInput
  ) {
    query(id: $id) {
      ...SelectedQueryHistoryItem
    }
  }
  ${SelectedQueryHistoryItemFragmentDoc}
  ${KeywordSearchResultFragmentDoc}
  ${SearchResultRatingFragmentDoc}
  ${ChatMessageFragmentDoc}
  ${SemanticSearchResultFragmentDoc}
`;

/**
 * __useGetSelectedQueryHistoryItemQuery__
 *
 * To run a query within a React component, call `useGetSelectedQueryHistoryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedQueryHistoryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedQueryHistoryItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *      chatFilterInput: // value for 'chatFilterInput'
 *      semanticSearchResultsFilterInput: // value for 'semanticSearchResultsFilterInput'
 *      keywordSearchResultsFilterInput: // value for 'keywordSearchResultsFilterInput'
 *   },
 * });
 */
export function useGetSelectedQueryHistoryItemQuery(
  baseOptions: Apollo.QueryHookOptions<GetSelectedQueryHistoryItemQuery, GetSelectedQueryHistoryItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSelectedQueryHistoryItemQuery, GetSelectedQueryHistoryItemQueryVariables>(
    GetSelectedQueryHistoryItemDocument,
    options,
  );
}
export function useGetSelectedQueryHistoryItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSelectedQueryHistoryItemQuery,
    GetSelectedQueryHistoryItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSelectedQueryHistoryItemQuery, GetSelectedQueryHistoryItemQueryVariables>(
    GetSelectedQueryHistoryItemDocument,
    options,
  );
}
export function useGetSelectedQueryHistoryItemSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSelectedQueryHistoryItemQuery,
    GetSelectedQueryHistoryItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSelectedQueryHistoryItemQuery, GetSelectedQueryHistoryItemQueryVariables>(
    GetSelectedQueryHistoryItemDocument,
    options,
  );
}
export type GetSelectedQueryHistoryItemQueryHookResult = ReturnType<typeof useGetSelectedQueryHistoryItemQuery>;
export type GetSelectedQueryHistoryItemLazyQueryHookResult = ReturnType<typeof useGetSelectedQueryHistoryItemLazyQuery>;
export type GetSelectedQueryHistoryItemSuspenseQueryHookResult = ReturnType<
  typeof useGetSelectedQueryHistoryItemSuspenseQuery
>;
export type GetSelectedQueryHistoryItemQueryResult = Apollo.QueryResult<
  GetSelectedQueryHistoryItemQuery,
  GetSelectedQueryHistoryItemQueryVariables
>;
