import { Flex } from '@mantine/core';
import React from 'react';
import { useForm } from '@mantine/form';
import { useSpeechRecognition } from 'react-speech-recognition';
import { IconInfoCircle, IconMessageChatbot, IconSearch } from '@tabler/icons-react';
import { useChatStore } from './ChatStoreProvider';
import { useDidMountEffect } from '../../../hooks';
import { SearchInput } from '../../../components';
import { useSearchHistoryEntrySections } from '../SearchHistoryEntrySections';

export const ChatInput = () => {
  const { setActiveTab, withChat, activeTab } = useSearchHistoryEntrySections();
  const icon = !withChat ? <IconInfoCircle /> : activeTab === 'searchResults' ? <IconMessageChatbot /> : <IconSearch />;

  const { onReplyToChat, postReplyToChatStreamingId, editableUserMessage, setEditableUserMessage } = useChatStore();
  const { transcript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();

  const chatForm = useForm<{ message: string }>({
    name: 'AiChatForm',
    initialValues: {
      message: editableUserMessage?.text || '',
    },
    validate: {
      message: (value) => {
        const isValid = value.replace(/\r\n|\r|\n/g, '').length >= 3;
        return isValid ? null : 'Name must have at least 3 letters';
      },
    },
    validateInputOnBlur: false,
    onValuesChange: (values) => {
      // console.log({ values });
    },
  });

  useDidMountEffect(() => {
    chatForm.setFieldValue('message', transcript);
  }, [transcript]);

  useDidMountEffect(() => {
    chatForm.setFieldValue('message', editableUserMessage?.text || '');
  }, [editableUserMessage?.text]);

  const handleSearch = async () => {
    if (!chatForm.validate().hasErrors) {
      await onReplyToChat(chatForm.values.message, editableUserMessage?.id).then(() => {
        setEditableUserMessage(null);
        chatForm.setFieldValue('message', '');
      });
    }
  };

  return (
    <Flex
      // pt="var(--app-shell-padding)"
      // pb="var(--app-shell-padding)"
      w="100%"
      gap={0}
      justify="space-between"
      align="center"
      direction="column"
    >
      <SearchInput
        key={chatForm.key('query')}
        {...chatForm.getInputProps('message')}
        placeholder="Message to ..."
        onInputSubmit={handleSearch}
        loading={!!postReplyToChatStreamingId}
        withFilterTrigger={false}
        infoIconProps={{
          children: icon,
          onClick: () => setActiveTab((prevState) => (prevState === 'searchResults' ? 'chat' : 'searchResults')),
          disabled: !withChat,
        }}
        // onSpeechRecognitionChange={onSpeechRecognitionChange}
      />
    </Flex>
  );
};
