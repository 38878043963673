import { useCallback, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export const useDebounce = <T = (...args: any[]) => void | undefined>(callback: T, delay: number) => {
  const timer = useRef<any>(null);

  return useCallback(
    (...args) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      if (typeof callback === 'function') {
        timer.current = setTimeout(() => {
          callback(...args);
        }, delay);
      }
    },
    [callback, delay],
  );
};
