import React from 'react';
import { useDisclosure } from '@mantine/hooks';
import { ActionIcon, Kbd, Popover, Text } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';

export interface ActionInputHelperProps {
  children?: React.ReactNode;
  description?: React.ReactNode;
}

export const ActionInputHelper = (props: ActionInputHelperProps) => {
  const { children, description } = props;
  const [opened, { close, open }] = useDisclosure(false);
  return (
    <Popover width={200} position="bottom" withArrow shadow="md" opened={opened}>
      <Popover.Target>
        <ActionIcon variant="transparent" disabled styles={{ root: { border: 'none' } }}>
          <IconSearch onMouseEnter={open} onMouseLeave={close} style={{ cursor: 'help' }} />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown style={{ pointerEvents: 'none' }}>
        <Text size="xs">Keyboard events:</Text>
        <Text size="xs">
          Send message - <Kbd size="xs">Enter</Kbd>
        </Text>
        <Text size="xs">
          Add new line - <Kbd size="xs">Shift</Kbd>+<Kbd size="xs">Enter</Kbd>
        </Text>
      </Popover.Dropdown>
    </Popover>
  );
};
