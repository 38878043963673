import React from 'react';
import { ActionIcon, MantineColorScheme, Menu, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { IconMoonStars, IconSun } from '@tabler/icons-react';
import { useAppTheme } from './AppThemeProvider';

export interface IAppThemeSwitcherProps {
  type?: 'dropdown' | 'menu';
}

export const AppThemeSwitcher = (props: IAppThemeSwitcherProps): React.ReactElement => {
  const { type = 'dropdown' } = props;

  const { appColorScheme, mantineColorScheme, mantineTheme: theme, setMantineColorScheme } = useAppTheme();

  const themeIcon = (colorScheme: string, size: string | number = 28) => {
    return colorScheme === 'light' ? (
      <IconSun size={size} stroke={2} color={theme.colors.yellow[5]} />
    ) : (
      <IconMoonStars size={size} />
    );
  };

  if (type === 'dropdown') {
    return (
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <ActionIcon
            // size="sm"
            variant="transparent"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {appColorScheme === 'dark' ? (
              <IconMoonStars color={theme.white} />
            ) : (
              <IconSun stroke={2} color={theme.colors.yellow[5]} />
            )}
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Themes</Menu.Label>
          {(['light', 'dark', 'auto'] as MantineColorScheme[]).map((item, key) => (
            <Menu.Item
              key={`themes-menu-${key}`}
              leftSection={themeIcon(item, '1rem')}
              color={mantineColorScheme === item ? theme.colors.egwPrimary[5] : undefined}
              rightSection={item}
              styles={{
                item: {
                  pointerEvents: mantineColorScheme === item ? 'none' : 'all',
                },
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (mantineColorScheme !== item) {
                  setMantineColorScheme(item);
                }
              }}
            />
          ))}
        </Menu.Dropdown>
      </Menu>
    );
  }
  return <></>;
};
