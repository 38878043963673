import React from 'react';
import { AppShell, Tabs } from '@mantine/core';
import { IconFilterPlus, IconHistory } from '@tabler/icons-react';
import { useElementSize } from '@mantine/hooks';
import { QueryHistory } from './QueryHistory';
import { FooterLayout } from '../FooterLayout';
import './assets/styles/naw.scss';
import { NavbarBurger } from './NavbarBurger';
import { SearchFilters } from './SearchFilters';
import { useAppLayout } from '../AppLayoutProvider';

export type NavBarActiveTabType = 'filters' | 'history' | null;

export const NavbarLayout = () => {
  const { ref: firstSectionRef, height: firstSectionHeight } = useElementSize();
  const { ref: lastSectionRef, height: lastSectionHeight } = useElementSize();
  const { ref: tabListRef, width: tabListWidth } = useElementSize();
  const { navbarStatus, setNavbarStatus } = useAppLayout();
  return (
    <AppShell.Navbar>
      <NavbarBurger />
      <AppShell.Section ref={firstSectionRef} id="new-chat-button-navbar--portal-wrapper" />
      <AppShell.Section grow w="100%" h={`calc(100% - ${firstSectionHeight}px - ${lastSectionHeight}px`}>
        <Tabs
          defaultValue="filters"
          value={navbarStatus || 'filters'}
          onChange={(value) => {
            setNavbarStatus(value as NavBarActiveTabType);
          }}
          orientation="vertical"
          styles={{
            root: {
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
            },
            tab: {
              padding: 'var(--mantine-spacing-sm)',
            },
            panel: {
              width: `calc(100% - ${tabListWidth}px)`,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: 5,
            },
          }}
        >
          <Tabs.List ref={tabListRef}>
            <Tabs.Tab value="filters" leftSection={<IconFilterPlus />} />
            <Tabs.Tab value="history" leftSection={<IconHistory />} />
          </Tabs.List>
          <Tabs.Panel value="filters" title="Filters">
            <SearchFilters />
          </Tabs.Panel>
          <Tabs.Panel value="history" title="History">
            <QueryHistory />
          </Tabs.Panel>
        </Tabs>
      </AppShell.Section>
      <AppShell.Section style={{ width: '100%' }} ref={lastSectionRef}>
        <FooterLayout isNavbar />
      </AppShell.Section>
    </AppShell.Navbar>
  );
};
