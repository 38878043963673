import React from 'react';
import { ActionIcon, Flex, Group, Textarea } from '@mantine/core';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { IconMicrophone, IconMicrophoneOff, IconSend2 } from '@tabler/icons-react';
import { useDebouncedCallback, useDidUpdate } from '@mantine/hooks';
import { v4 as uuidV4 } from 'uuid';
import { useSearchStore } from './SearchStoreProvider';
import { ActionInputHelper } from '../../components';
import { useAppTheme } from '../../themes';

export const SearchInput = () => {
  const { mantineTheme: theme, appColorScheme: colorScheme } = useAppTheme();

  const { onSearch, sendSearchQueryStore, sendSearchQuery, searchForm } = useSearchStore();
  const { transcript, finalTranscript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();

  useDidUpdate(() => {
    if (finalTranscript.length > 3) {
      onSpeechRecognitionTriggerSearch(finalTranscript);
      // handleSpeechRecognitionTriggerSearch(finalTranscript).then(() => onSearch());
    }
  }, [finalTranscript]);
  const handleSearch = async () => {
    await onSearch();
  };

  const handleSpeechRecognitionTriggerSearch = async (query: string) => {
    searchForm.setFieldValue('query', query);
  };
  const onSpeechRecognitionTriggerSearch = useDebouncedCallback(async (query: string) => {
    searchForm.setFieldValue('query', query);
    if (query.length > 3) {
      await sendSearchQuery({
        variables: {
          input: { ...searchForm.values, id: uuidV4(), query },
        },
      }).catch((error) => {
        console.warn('Search error', { error });
      });
    }
  }, 500);

  const iconColor = colorScheme === 'light' ? theme.colors.dark[9] : theme.colors.gray[5];

  return (
    <Flex w="100%" gap={5} justify="space-between" align="center" direction="row">
      <Textarea
        {...searchForm.getInputProps('query')}
        placeholder="Input you search query ..."
        size="xs"
        w="100%"
        minRows={1}
        maxRows={4}
        autoFocus
        onFocus={(e) => {
          const { value } = e.target;
          e?.target?.setSelectionRange(value.length, value.length);
        }}
        autosize
        styles={{
          wrapper: {
            width: '100%',
          },
          input: {
            msOverflowStyle: 'none' /* Internet Explorer 10+ */,
            scrollbarWidth: 'none' /* Firefox */,
            WebkitScrollbar: { display: 'none' },
          },
          section: {
            alignItems: 'end',
          },
        }}
        leftSection={<ActionInputHelper />}
        rightSectionPointerEvents="all"
        rightSectionWidth={60}
        rightSection={
          <Group gap={1} wrap="nowrap">
            <ActionIcon
              variant="transparent"
              disabled={!browserSupportsSpeechRecognition || sendSearchQueryStore.loading}
              onClick={() => {
                return listening
                  ? SpeechRecognition.stopListening()
                  : SpeechRecognition.startListening({ interimResults: false });
              }}
            >
              {listening ? <IconMicrophoneOff color="green" /> : <IconMicrophone color={iconColor} />}
            </ActionIcon>
            <ActionIcon
              variant="transparent"
              disabled={(searchForm.values.query as string)?.replace(/\r\n|\r|\n/g, '').length < 3}
              loading={sendSearchQueryStore.loading}
              onClick={() => {
                void handleSearch();
              }}
            >
              <IconSend2
                color={(searchForm.values.query as string)?.replace(/\r\n|\r|\n/g, '').length < 3 ? 'gray' : iconColor}
              />
            </ActionIcon>
          </Group>
        }
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            void handleSearch();
          }
        }}
      />
    </Flex>
  );
};
