export interface IConstants {
  APP_TITLE: string;
  APP_COPYRIGHT: string;
  APP_ROOT_URL: string;
  APP_ROOT_URL_ENDPOINT: string;
  APP_WITH_HASH_ROUTER: boolean;
  APP_API_URL: string;
  APP_API_SESSION_ACCESS_URL: string;
  APP_API_SESSION_TOKEN_URL: string;
  APP_API_SESSION_PROVIDER: string;
  APP_API_CLIENT_ID: string;
  APP_API_SESSION_ACCESS_SCOPES: string;
  APP_WITH_CHAT: boolean;
  APP_AUTH_ROLE_REQUIRED?: string;
}

export type ReactAppConfigType = Partial<{
  REACT_APP_TITLE: string;
  REACT_APP_COPYRIGHT: string;
  REACT_APP_ROOT_URL: string;
  REACT_APP_ROOT_URL_ENDPOINT: string;
  REACT_APP_API_URL: string;
  REACT_APP_API_SESSION_ACCESS_URL: string;
  REACT_APP_API_SESSION_TOKEN_URL: string;
  REACT_APP_API_SESSION_PROVIDER: string;
  REACT_APP_API_CLIENT_ID: string;
  REACT_APP_API_SESSION_ACCESS_SCOPES: string;
  REACT_APP_WITH_HASH_ROUTER: boolean;
  REACT_APP_WITH_CHAT: boolean;
  REACT_APP_AUTH_ROLE_REQUIRED?: string;
}>;

declare global {
  interface Window {
    REACT_APP_CONFIG?: ReactAppConfigType;
  }
}

window.REACT_APP_CONFIG ??= {};
export const CONSTANTS: IConstants = {
  APP_TITLE: window?.REACT_APP_CONFIG?.REACT_APP_TITLE || process.env.REACT_APP_TITLE || 'Egw Search',
  APP_COPYRIGHT:
    window?.REACT_APP_CONFIG?.REACT_APP_COPYRIGHT || process.env.REACT_APP_COPYRIGHT || 'Ellen G. White® Estate',
  APP_ROOT_URL:
    window?.REACT_APP_CONFIG?.REACT_APP_ROOT_URL ||
    process.env.REACT_APP_ROOT_URL ||
    `${window.location.protocol}//${window.location.host}`,
  APP_ROOT_URL_ENDPOINT:
    window?.REACT_APP_CONFIG?.REACT_APP_ROOT_URL_ENDPOINT || process.env.REACT_APP_ROOT_URL_ENDPOINT || '',
  APP_WITH_HASH_ROUTER:
    window?.REACT_APP_CONFIG?.REACT_APP_WITH_HASH_ROUTER || process.env.REACT_APP_WITH_HASH_ROUTER === 'true',
  APP_API_URL: window?.REACT_APP_CONFIG?.REACT_APP_API_URL || process.env.REACT_APP_API_URL || '',
  APP_API_SESSION_ACCESS_URL:
    window?.REACT_APP_CONFIG?.REACT_APP_API_SESSION_ACCESS_URL || process.env.REACT_APP_API_SESSION_ACCESS_URL || '',
  APP_API_SESSION_TOKEN_URL:
    window?.REACT_APP_CONFIG?.REACT_APP_API_SESSION_TOKEN_URL || process.env.REACT_APP_API_SESSION_TOKEN_URL || '',
  APP_API_SESSION_PROVIDER:
    window?.REACT_APP_CONFIG?.REACT_APP_API_SESSION_PROVIDER || process.env.REACT_APP_API_SESSION_PROVIDER || '',
  APP_API_CLIENT_ID: window?.REACT_APP_CONFIG?.REACT_APP_API_CLIENT_ID || process.env.REACT_APP_API_CLIENT_ID || '',
  //
  APP_API_SESSION_ACCESS_SCOPES:
    window?.REACT_APP_CONFIG?.REACT_APP_API_SESSION_ACCESS_SCOPES ||
    process.env.REACT_APP_API_SESSION_ACCESS_SCOPES ||
    'openid offline_access roles writings search',
  APP_WITH_CHAT: window?.REACT_APP_CONFIG?.REACT_APP_WITH_CHAT || process.env.REACT_APP_WITH_CHAT === 'true',
  APP_AUTH_ROLE_REQUIRED:
    window?.REACT_APP_CONFIG?.REACT_APP_AUTH_ROLE_REQUIRED || process.env.APP_AUTH_ROLE_REQUIRED || undefined,
};
