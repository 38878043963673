import React, { ReactNode, useMemo, useState } from 'react';
import { Anchor, Grid, HoverCard, Loader, Stack, Text, Flex, rem } from '@mantine/core';
import { SemanticSearchResultDto, ChatMessageDto } from '../../../services/graphql/apolloAppClient';
import { useSearchHistoryEntryStore } from '../SearchHistoryEntryStoreProvider';
import { chatMessageTextStyles } from './ChatMessage';
import { useChatStore } from './ChatStoreProvider';
import { useAppTheme } from '../../../themes';
import { EgwSource } from '../../../components';

export type ChatMessageLinkHandlerType = (snippet: SemanticSearchResultDto) => void;

export type ChatSystemMessageTextRenderPropsType = {
  message: ChatMessageDto;
  onClick?: ChatMessageLinkHandlerType;
};

// const linkExtractor = /\(\s*(\d{1,3}(\s*[,-]\s*\d{1,3})*)\s*(,|\)|$)/gi;
const linkExtractor = /(\(|\\\[)\s*(\d{1,3}(\s*[,-]\s*\d{1,3})*)\s*(,|\)|\\\\]|$)/gi;

type LinkSetType = {
  part: number;
  originalPart: string;
  linkNumber: number;
  linkSetNumber: number;
}[];

type LinkOutputType = { linkSetNumber: number; start: number; end: number; text: string; links: LinkSetType };
type LinkSetOutputType = LinkOutputType[];

const getLinkSet = (text: string, linkSetNumber: number, linkSetOutput: LinkSetOutputType): LinkSetType => {
  const parts: LinkSetType = [];
  const normalizedText = text.replace(/^\(/, '').replace(/\)$/, '').trim();
  normalizedText
    .split(',')
    .map((s) => s.trim())
    .forEach((part, index) => {
      const prevLinkNumber =
        linkSetOutput[linkSetOutput.length - 1]?.links[(linkSetOutput[linkSetOutput.length - 1]?.links.length || 0) - 1]
          ?.linkNumber || 0;
      let clonedLinkNumber: number | null = null;
      linkSetOutput.every((linkSetItem) => {
        return linkSetItem.links.every((link) => {
          if (link.originalPart === part) {
            clonedLinkNumber = link.linkNumber;
            return false;
          }
          return true;
        });
      });
      if (part.includes('-')) {
        const [start, end] = part.split('-').map((s) => parseInt(s, 10));
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(start) || isNaN(end)) {
          // eslint-disable-next-line no-continue
          return;
        }
        for (let i = start; i <= end; i++) {
          parts.push({
            part: i,
            originalPart: part,
            linkNumber: clonedLinkNumber ?? prevLinkNumber + index + 1,
            linkSetNumber,
          });
        }
      } else {
        const parsedPart = parseInt(part, 10);
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(parsedPart)) {
          // eslint-disable-next-line no-continue
          return;
        }
        parts.push({
          part: parsedPart,
          originalPart: part,
          linkNumber: clonedLinkNumber ?? prevLinkNumber + index + 1,
          linkSetNumber,
        });
      }
    });
  return parts;
};

export const ChatSystemMessageTextRender = (props: ChatSystemMessageTextRenderPropsType) => {
  const { generateReplyToUserMessageStreamingId, postReplyToChatStreamingId } = useChatStore();
  const { message, onClick } = props;
  const { appColorScheme } = useAppTheme();
  const [hoveredLinkId, setHoveredLinkId] = useState<number | null>(null);

  let match;
  const output: ReactNode[] = [];
  const matches: RegExpExecArray[] = [];
  // eslint-disable-next-line no-cond-assign
  while ((match = linkExtractor.exec(message.text))) {
    matches.push(match);
  }
  let prevPosition = 0;
  let linkSetNumber = 0;
  const linkSetOutput: LinkSetOutputType = [];

  for (const match of matches) {
    const currentLinkSet: LinkOutputType = {
      start: match.index,
      end: match.index + match[0].length,
      text: message.text.slice(match.index, match.index + match[0].length),
      linkSetNumber: linkSetNumber + 1,
      links: getLinkSet(
        message.text.slice(match.index, match.index + match[0].length),
        linkSetNumber + 1,
        linkSetOutput,
      ),
    };
    output.push(
      <React.Fragment key={`t${match.index}`}>
        <Text
          id={`text_${message.type}_${message.id}_${match.index}`}
          maw="100%"
          size="sm"
          dangerouslySetInnerHTML={{ __html: message.text.slice(prevPosition, match.index) }}
          component="span"
          styles={chatMessageTextStyles}
        />
        <Text
          component="span"
          size="xs"
          style={{
            verticalAlign: 'super',
          }}
        >
          {currentLinkSet.links.map((r, i, arr) => {
            const startSection = i === 0 ? '( ' : ', ';
            const endSection = arr.length === i + 1 ? ' )' : '';
            return (
              <React.Fragment key={`ln-${r.linkNumber}-i`}>
                {startSection}
                <Text
                  component="span"
                  size="xs"
                  onMouseEnter={() => {
                    setHoveredLinkId(r.linkNumber);
                  }}
                  onMouseLeave={() => {
                    setHoveredLinkId(null);
                  }}
                >
                  {r.linkNumber}
                </Text>
                {endSection}
              </React.Fragment>
            );
          })}
          {/* {` / `} */}
          {/* {message.text.slice(match.index, match.index + match[0].length)} */}
        </Text>
        <br />
      </React.Fragment>,
    );
    linkSetNumber += 1;
    linkSetOutput.push(currentLinkSet);
    prevPosition = match.index + match[0].length;
  }

  return (
    <>
      {output.length > 0 ? (
        output
      ) : (
        <Text
          maw="100%"
          size="sm"
          dangerouslySetInnerHTML={{ __html: message.text }}
          component="span"
          styles={chatMessageTextStyles}
        />
      )}
      <ChatMessageSources linkSetOutput={linkSetOutput} onClick={onClick} hoveredLinkId={hoveredLinkId} />
      {(generateReplyToUserMessageStreamingId === message.id || postReplyToChatStreamingId === message.id) && (
        <Loader
          styles={{ root: { display: 'inline-flex', paddingLeft: 3 } }}
          size="sm"
          color={appColorScheme === 'dark' ? 'white' : 'black'}
          type="dots"
        />
      )}
    </>
  );
};

export interface ChatMessageSourcesProps {
  linkSetOutput: LinkSetOutputType;
  hoveredLinkId: number | null;
  onClick?: ChatMessageLinkHandlerType;
}
export interface ChatMessageSourceLinkProps {
  index: number;
  partNumber: number;
  hoveredLinkId: number | null;
  onClick?: ChatMessageLinkHandlerType;
}
export const ChatMessageSourceLink: React.FC<ChatMessageSourceLinkProps> = (props) => {
  const { index, partNumber, hoveredLinkId, onClick } = props;
  const { searchHistoryEntryStore } = useSearchHistoryEntryStore();
  const snippet = searchHistoryEntryStore?.data?.query?.searchResults
    ?.map((r) => r)
    .find((r) => r.order === partNumber);
  const handleClick = useMemo(
    () => (e: React.MouseEvent) => {
      snippet && onClick?.(snippet);
      e.stopPropagation();
      e.preventDefault();
    },
    [onClick, snippet],
  );
  if (!snippet) {
    return null;
  }

  return (
    <HoverCard width={280} withArrow clickOutsideEvents={['mouseup', 'touchend']} openDelay={0} closeDelay={0}>
      <HoverCard.Target>
        <Anchor
          component={snippet?.uri ? 'a' : 'span'}
          underline={hoveredLinkId === index ? 'always' : 'hover'}
          href={snippet?.uri}
          target="_blank"
          rel="noreferrer"
          size="xs"
          styles={{
            root: {
              fontFamily: 'Roboto',
              fontWeight: 400,
              display: 'flex',
              alignItems: 'baseline',
              flexWrap: 'nowrap',
              margin: 3,
              gap: rem(1),
              ...(snippet?.uri
                ? {}
                : {
                    cursor: 'default',
                    textDecoration: 'none',
                    color: 'inherit',
                  }),
            },
          }}
        >
          <Flex wrap="nowrap" align="center">
            <Text component="span">{`${index})`}</Text>
            <EgwSource size="xs" style={{ marginLeft: rem(1), marginRight: rem(1) }} />
          </Flex>
          <Text component="span" lineClamp={1} truncate="end">
            {snippet.referenceCode}
          </Text>
        </Anchor>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text component="span" size="sm" styles={chatMessageTextStyles}>
          {snippet.snippet}
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

export const ChatMessageSources: React.FC<ChatMessageSourcesProps> = (props) => {
  const { linkSetOutput, hoveredLinkId, onClick } = props;

  const linkParts = useMemo(() => {
    const parts: number[] = [];
    const links: LinkSetType = [];

    linkSetOutput.forEach((linkSetItem) => {
      return linkSetItem.links.forEach((link) => {
        if (!parts.includes(link.part)) {
          parts.push(link.part);
          links.push(link);
        }
      });
    });
    return links;
  }, [linkSetOutput]);

  return (
    <>
      {linkSetOutput?.length > 0 && (
        <Stack px={10} pb={10} gap={10} w="100%">
          <Text
            component="span"
            styles={{
              root: {
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            }}
          >
            Sources:
          </Text>
          <Grid
            mx={5}
            styles={{
              col: {
                padding: 0,
              },
            }}
          >
            {linkParts.map((link) => (
              <Grid.Col key={link.part} span={{ base: 12, md: 6, lg: 3 }}>
                <ChatMessageSourceLink
                  hoveredLinkId={hoveredLinkId}
                  index={link.linkNumber}
                  partNumber={link.part}
                  onClick={onClick}
                />
              </Grid.Col>
            ))}
          </Grid>
        </Stack>
      )}
    </>
  );
};
