import {
  Accordion,
  ActionIcon,
  Box,
  Center,
  Flex,
  Loader,
  Notification,
  ScrollArea,
  Text,
  TextInput,
  Title,
  useVirtualizedCombobox,
} from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconFilter, IconTrash } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';
import { useElementSize, useViewportSize } from '@mantine/hooks';
import { QueryHistoryItem } from './QueryHistoryItem';
import { useClearQueryHistoryMutation } from '../../../services/graphql/apolloAppClient';
import { useQueryHistory } from './QueryHistoryProvider';
import { useAppTheme } from '../../../themes';

export const QueryHistory: React.FC<{ height?: number }> = (props) => {
  const { height } = props;
  const viewportRef = useRef<HTMLDivElement>(null);
  const [filterQuery, setFilterQuery] = useState('');
  const { mantineTheme: theme } = useAppTheme();
  const [hovered, setHovered] = useState(-1);
  const navigate = useNavigate();
  const { historyId } = useParams<{ historyId: string }>();
  const { queryHistory } = useQueryHistory();
  const { ref: historyControlRef, height: historyControlHeight } = useElementSize();
  const { ref: historyFilterRef, height: historyFilterHeight } = useElementSize();

  const [clearQueryHistory, clearQueryHistoryResult] = useClearQueryHistoryMutation({
    onCompleted: (data, queryOptions) => {
      if (data?.clearHistory?.boolean) {
        notifications.show({
          title: 'Clear history',
          message: 'Clear history status: true',
        });
        if (historyId) {
          navigate('/');
        }
        void queryHistory?.refetch();
      }
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  const handleClearHistory = () => {
    modals.openConfirmModal({
      title: 'Clear history!',
      closeOnConfirm: !clearQueryHistoryResult.loading,
      centered: true,
      children: <Text size="sm">Do you want to clear your history? This action is destructive.</Text>,
      labels: { confirm: 'Clear', cancel: 'Cancel' },
      confirmProps: { color: 'red', loading: clearQueryHistoryResult.loading },
      onConfirm: async () => {
        await clearQueryHistory();
      },
    });
  };

  const [filtered, setFiltered] = useState(
    queryHistory?.data?.queries?.items?.filter((item) =>
      item.query.toLowerCase().includes(filterQuery.toLowerCase()),
    ) || [],
  );

  useEffect(() => {
    setFiltered(
      queryHistory?.data?.queries?.items?.filter((item) =>
        item.query.toLowerCase().includes(filterQuery.toLowerCase()),
      ) || [],
    );
  }, [queryHistory?.data?.queries?.items, filterQuery]);

  const loader = (
    <Flex w="100%" h={50} gap={5} justify="center" align="center" wrap="nowrap">
      <Loader variant="dots" />
    </Flex>
  );

  return (
    <>
      <Flex wrap="nowrap" p="var(--mantine-spacing-xs)" ref={historyControlRef}>
        <Title order={4}>History</Title>
        <ActionIcon
          disabled={clearQueryHistoryResult.loading}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClearHistory();
          }}
          styles={{
            root: {
              marginLeft: 'auto',
            },
          }}
          variant="subtle"
          color={theme.colors.red[5]}
          loading={clearQueryHistoryResult.loading}
        >
          <IconTrash />
        </ActionIcon>
      </Flex>
      {queryHistory.loading ? (
        loader
      ) : !!queryHistory?.data?.queries?.items && queryHistory?.data?.queries?.items.length > 0 ? (
        <>
          <TextInput
            ref={historyFilterRef}
            w="100%"
            value={filterQuery}
            onChange={(event) => {
              setFilterQuery(event.currentTarget.value);
              setHovered(-1);
            }}
            onKeyDown={(event) => {
              if (event.key === 'ArrowDown') {
                event.preventDefault();
                setHovered((current) => {
                  const nextIndex = current + 1 >= filtered.length ? current : current + 1;
                  viewportRef.current
                    ?.querySelectorAll('[data-list-item]')
                    ?.[nextIndex]?.scrollIntoView({ block: 'nearest' });
                  return nextIndex;
                });
              }

              if (event.key === 'ArrowUp') {
                event.preventDefault();
                setHovered((current) => {
                  const nextIndex = current - 1 < 0 ? current : current - 1;
                  viewportRef.current
                    ?.querySelectorAll('[data-list-item]')
                    ?.[nextIndex]?.scrollIntoView({ block: 'nearest' });
                  return nextIndex;
                });
              }
            }}
            placeholder="Filter queries"
          />
          <ScrollArea
            type="scroll"
            h={`calc(100% - ${historyControlHeight}px - ${historyFilterHeight}px - 1`}
            w="100%"
            mt={1}
            viewportRef={viewportRef}
            scrollbarSize={0}
            offsetScrollbars
            styles={{ viewport: { width: '100%' } }}
            className="naw-section-scroll-area"
          >
            {filtered.map((q, i) => (
              <QueryHistoryItem
                isActive={q.id === historyId}
                key={`qh-${q.id || i}`}
                itemData={q}
                effectCallback={queryHistory.refetch}
              />
            ))}
          </ScrollArea>
        </>
      ) : (
        <Notification
          icon={<></>}
          styles={{
            root: {
              background: 'transparent',
              boxShadow: 'none',
              paddingInlineStart: 'calc(1.375rem* var(--mantine-scale))',
            },
            icon: { display: 'none' },
          }}
          withCloseButton={false}
        >
          Your history is empty
        </Notification>
      )}
    </>
  );
};
