import React from 'react';
import { Box, Loader, LoadingOverlay } from '@mantine/core';
import { SearchHistoryEntryStoreProvider, useSearchHistoryEntryStore } from './SearchHistoryEntryStoreProvider';
import { useTitle } from '../../hooks/useTitle';
import { CONSTANTS } from '../../config';
import { NewSearchSessionButtonPortalRender } from '../../components';
import { SearchStoreProvider } from '../SearchPage/SearchStoreProvider';
import { SearchHistoryEntrySections } from './SearchHistoryEntrySections';

const SearchHistoryEntryPageComponent = () => {
  const { searchHistoryEntryStore, sendSearchQueryStore } = useSearchHistoryEntryStore();
  useTitle(searchHistoryEntryStore?.data?.query?.query);

  const withChat =
    searchHistoryEntryStore?.data?.query?.__typename === 'SemanticHistoryEntryDto' && CONSTANTS.APP_WITH_CHAT;

  return (
    <Box pos="relative" h="100%" w="100%">
      <LoadingOverlay
        visible={
          searchHistoryEntryStore?.data?.query === undefined ||
          sendSearchQueryStore.loading ||
          searchHistoryEntryStore.loading
        }
        loaderProps={{ children: <Loader size={50} /> }}
      />
      {searchHistoryEntryStore?.data?.query && <SearchHistoryEntrySections withChat={withChat} />}
    </Box>
  );
};

export const SearchHistoryEntryPage = () => {
  return (
    <SearchHistoryEntryStoreProvider>
      <NewSearchSessionButtonPortalRender />
      <SearchStoreProvider>
        <SearchHistoryEntryPageComponent />
      </SearchStoreProvider>
    </SearchHistoryEntryStoreProvider>
  );
};
