import { Navigate, redirect, RouteObject } from 'react-router-dom';
import React from 'react';
import { AppLayout, AuthLayout, MainLayout } from '../layout';
import { AuthPage, AuthRedirectPage, SearchHistoryEntryPage, SearchPage } from '../pages';
import { SecuredRoute } from './SecuredRoute';

export const ROUTES_MAP: RouteObject[] = [
  {
    element: <AppLayout />,
    id: 'AppLayout',
    children: [
      {
        id: 'AuthLayout',
        path: '/auth',
        element: <AuthLayout />,
        children: [
          {
            id: 'Auth',
            path: '',
            element: <AuthPage />,
          },
          // {
          //   id: 'Auth',
          //   path: 'redirect',
          //   element: <AuthRedirectPage />,
          // },
        ],
      },
      {
        id: 'SecuredRoute',
        path: '/',
        element: (
          <SecuredRoute>
            <MainLayout />
          </SecuredRoute>
        ),
        children: [
          {
            id: 'Search',
            path: '',
            element: <SearchPage />,
          },
          {
            id: 'SearchHistoryEntry',
            path: ':historyId',
            element: <SearchHistoryEntryPage />,
          },
          {
            path: '*',
            element: <Navigate to="/" replace />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
];
