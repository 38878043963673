import React, { createContext, useContext, useMemo } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { useGetQueryHistoryQuery } from '../../../services/graphql/apolloAppClient';
import { useAppAuth } from '../../../services';

export interface IUseQueryHistoryResult {
  queryHistory: ReturnType<typeof useGetQueryHistoryQuery>;
}

export const QueryHistoryContext = createContext<IUseQueryHistoryResult | undefined>(undefined);
QueryHistoryContext.displayName = 'QueryHistoryContext';

export const useQueryHistory = (): IUseQueryHistoryResult => {
  const context = useContext(QueryHistoryContext);
  if (!context) {
    console.warn('useQueryHistory must be used within QueryHistoryProvider');
  }
  return context as IUseQueryHistoryResult;
};

export const QueryHistoryProvider = ({ children }) => {
  const { isAuthenticated } = useAppAuth();
  const queryHistory = useGetQueryHistoryQuery({
    fetchPolicy: 'network-only',
    onError: (error) => {
      console.log('Query History ERROR', { error });
    },
    skip: !isAuthenticated,
  });

  const queryHistoryContextObject: IUseQueryHistoryResult = useMemo(
    () => ({
      queryHistory,
    }),
    [queryHistory],
  );

  return <QueryHistoryContext.Provider value={queryHistoryContextObject}>{children}</QueryHistoryContext.Provider>;
};
