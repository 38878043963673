import React from 'react';
import { Flex, Group } from '@mantine/core';
import { LoginSection } from './LoginSection';
import { AppThemeSwitcher } from '../../themes';

export interface IAppSettingsSectionProps {
  isAuthenticated?: boolean;
}
export const AppSettingsSection: React.FC = () => {
  return (
    <Flex h="100%" gap="var(--mantine-spacing-xs)" justify="space-between" align="center" direction="row" wrap="nowrap">
      <Group id="settings-menu-mobile--portal-wrapper" gap="var(--mantine-spacing-xs)" wrap="nowrap" />
      <Group gap="var(--mantine-spacing-xs)" wrap="nowrap">
        <AppThemeSwitcher />
        <LoginSection />
      </Group>
    </Flex>
  );
};
