import { Chip, Flex, Group } from '@mantine/core';
import React from 'react';
import { SearchTypeEnum } from '../../services/graphql/apolloAppClient';
import { useSearchStore } from './SearchStoreProvider';
import { useAppTheme } from '../../themes';

export const SearchTypeFilter = () => {
  const { searchForm } = useSearchStore();
  const settings = [
    { value: SearchTypeEnum.Auto, title: 'Auto' },
    { value: SearchTypeEnum.AiSearch, title: 'Q&A Search' },
    { value: SearchTypeEnum.KeywordSearch, title: 'Keywords' },
  ];

  return (
    <Flex gap={5} justify="center" align="center" direction="row" wrap="wrap">
      {settings.map((s, i) => (
        <Chip
          key={`settingsChip-${i}`}
          radius={6}
          checked={searchForm.values.type === s.value}
          variant="light"
          value={s.value}
          onChange={(checked) => {
            searchForm.setFieldValue('type', checked ? s.value : undefined);
          }}
        >
          {s.title}
        </Chip>
      ))}
    </Flex>
  );
};
