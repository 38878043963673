import React, { createContext, useContext, useMemo, useState } from 'react';
import { FolderListItemFragment, useGetFoldersListQuery } from '../../../../services/graphql/apolloAppClient';
import {
  MantineTreeBasicDataNodeType,
  MantineTreeCheckedStateType,
  parseMantineTreeData,
} from '../../../../components';
import { useAppAuth } from '../../../../services';

export type FoldersTreeFilterNodeType = MantineTreeBasicDataNodeType &
  FolderListItemFragment & {
    children?: FoldersTreeFilterNodeType[];
  };

export interface IUseFoldersStoreResult {
  folders: ReturnType<typeof useGetFoldersListQuery>;
  foldersTree: FoldersTreeFilterNodeType[];
  checkedFolders: MantineTreeCheckedStateType;
  setCheckedFolders: React.Dispatch<React.SetStateAction<MantineTreeCheckedStateType>>;
  callback: ((checkedFolders?: MantineTreeCheckedStateType) => void) | undefined;
  setCallback: React.Dispatch<
    React.SetStateAction<((checkedFolders?: MantineTreeCheckedStateType) => void) | undefined>
  >;
}

export const FoldersStoreContext = createContext<IUseFoldersStoreResult | undefined>(undefined);
FoldersStoreContext.displayName = 'FoldersStoreContext';

export const useFoldersStore = (): IUseFoldersStoreResult => {
  const context = useContext(FoldersStoreContext);
  if (!context) {
    console.warn('useFoldersStoreContext must be used within FoldersStoreProvider');
  }
  return context as IUseFoldersStoreResult;
};

export const FoldersStoreProvider = ({ children }) => {
  const { isAuthenticated } = useAppAuth();

  const [checkedFolders, setCheckedFolders] = useState<MantineTreeCheckedStateType>([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [foldersTree, setFoldersTree] = useState<FoldersTreeFilterNodeType[]>([]);
  const [callback, setCallback] = useState<IUseFoldersStoreResult['callback']>(undefined);
  const folders = useGetFoldersListQuery({
    fetchPolicy: 'network-only',
    onCompleted: (newData) => {
      setFoldersTree([
        ...parseMantineTreeData<FoldersTreeFilterNodeType>(newData.folders, {
          key: 'id',
          title: 'title',
          children: 'children',
          parentKey: 'parentId',
        }),
      ]);
      setCheckedFolders(newData?.folders?.filter((r) => r.checked).map((r) => r.id));
    },
    onError: (error) => {
      console.log({ error });
    },
    skip: !isAuthenticated,
  });

  const foldersStoreContextObject: IUseFoldersStoreResult = useMemo(
    () => ({
      folders,
      foldersTree,
      checkedFolders,
      setCheckedFolders,
      isChecked,
      setIsChecked,
      callback,
      setCallback,
    }),
    [checkedFolders, callback, isChecked, folders, foldersTree],
  );

  return <FoldersStoreContext.Provider value={foldersStoreContextObject}>{children}</FoldersStoreContext.Provider>;
};
