import {
  ActionIcon,
  Button,
  NavLink,
  Portal,
  PortalProps,
  Title,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { IconMessagePlus } from '@tabler/icons-react';
import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { useAppTheme } from '../themes';
import { useAppLayout } from '../layout/AppLayoutProvider';

export interface NewChatButtonProps {
  type: 'icon' | 'button' | 'navLink';
}

export const NewSearchSessionButton: React.FC<NewChatButtonProps> = (props) => {
  const { type } = props;
  const { mantineTheme: theme, appColorScheme: colorScheme } = useAppTheme();
  const { navbarStatus, setNavbarStatus } = useAppLayout();
  const iconColor = colorScheme === 'light' ? theme.colors.dark[9] : theme.colors.gray[5];

  if (type === 'button') {
    return (
      <Button
        variant="default"
        size="xs"
        styles={{
          label: {
            fontFamily: 'Roboto',
            fontWeight: 400,
            color: iconColor,
            overflow: 'visible',
          },
        }}
        leftSection={<IconMessagePlus color={iconColor} />}
        to="/"
        title="New search"
        component={RouterNavLink}
        onClick={() => !!navbarStatus && setNavbarStatus(null)}
      >
        New search
      </Button>
    );
  }

  if (type === 'navLink') {
    return (
      <NavLink
        to="/"
        label={<Title order={4}>New search</Title>}
        component={RouterNavLink}
        leftSection={<IconMessagePlus />}
        active
        onClick={() => {
          setNavbarStatus((prevState) => (prevState !== null ? null : prevState));
        }}
      />
    );
  }

  return (
    <ActionIcon
      variant="transparent"
      to="/"
      component={RouterNavLink}
      onClick={() => () => !!navbarStatus && setNavbarStatus(null)}
    >
      <IconMessagePlus color="white" size={28} stroke={2} />
    </ActionIcon>
  );
};

export const NewSearchSessionButtonPortalRender: React.FC = (props) => {
  const { isMobileLayout, isTargetWidthLayout } = useAppTheme();
  return (
    <>
      <Portal target="#new-chat-button-navbar--portal-wrapper">
        <NewSearchSessionButton type="navLink" />
      </Portal>
      {(isMobileLayout || isTargetWidthLayout) && (
        <Portal target="#settings-menu-mobile--portal-wrapper">
          <NewSearchSessionButton type="icon" />
        </Portal>
      )}
    </>
  );
};
