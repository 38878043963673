import React from 'react';
import { ActionIcon, ActionIconProps, Group, Kbd, Popover, Text, Textarea } from '@mantine/core';
import { IconFilterPlus, IconMicrophone, IconMicrophoneOff, IconSearch, IconSend2, IconX } from '@tabler/icons-react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useDebouncedCallback, useDidUpdate, useDisclosure, useUncontrolled } from '@mantine/hooks';
import { useAppTheme } from '../themes';
import { useAppLayout } from '../layout/AppLayoutProvider';

const InputHelper = (props: ActionIconProps & { onClick?: React.MouseEventHandler<HTMLButtonElement> }) => {
  const { children = <IconSearch />, ...restProps } = props;
  const [opened, { close, open }] = useDisclosure(false);
  return (
    <Popover width={200} position="bottom" withArrow shadow="md" opened={opened}>
      <Popover.Target>
        <ActionIcon variant="subtle" color="gray" onMouseEnter={open} onMouseLeave={close} {...restProps}>
          {children}
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown style={{ pointerEvents: 'none' }}>
        <Text size="xs">Keyboard events:</Text>
        <Text size="xs">
          Send message - <Kbd size="xs">Enter</Kbd>
        </Text>
        <Text size="xs">
          Add new line - <Kbd size="xs">Shift</Kbd>+<Kbd size="xs">Enter</Kbd>
        </Text>
      </Popover.Dropdown>
    </Popover>
  );
};

export interface SearchInputPropsType {
  infoIconProps?: ActionIconProps & { onClick?: React.MouseEventHandler<HTMLButtonElement> };
  placeholder?: string;
  onSpeechRecognitionChange?: (value: string) => void;
  onInputSubmit?: (value: string) => void;
  loading?: boolean;
  actionDisabled?: boolean;
  withFilterTrigger?: boolean;
  value?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
}

export const SearchInput = (props: SearchInputPropsType) => {
  const {
    onSpeechRecognitionChange,
    loading,
    onInputSubmit,
    value = '',
    defaultValue = '',
    placeholder,
    onChange,
    withFilterTrigger = true,
    infoIconProps,
  } = props;
  const { setNavbarStatus } = useAppLayout();
  const { isMobileLayout, isTargetWidthLayout } = useAppTheme();

  const { transcript, finalTranscript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();

  const [_value, handleChange] = useUncontrolled<string>({
    value,
    defaultValue,
    finalValue: '',
    onChange: (value) => {
      onChange?.(value);
    },
  });

  const handleInputSubmit = () => {
    onInputSubmit?.(_value);
  };

  useDidUpdate(() => {
    if (finalTranscript.length > 3) {
      onSpeechRecognitionTriggerSearch(finalTranscript);
    }
  }, [finalTranscript]);

  const onSpeechRecognitionTriggerSearch = useDebouncedCallback(async (value: string) => {
    handleChange(value);
    onSpeechRecognitionChange?.(value);
  }, 500);

  const iconListening = _value ? <IconX /> : listening ? <IconMicrophoneOff /> : <IconMicrophone />;
  const renderFilterTrigger = (isMobileLayout || isTargetWidthLayout) && withFilterTrigger;

  return (
    <Textarea
      w="100%"
      placeholder={placeholder}
      minRows={1}
      maxRows={4}
      autoFocus
      onFocus={(e) => {
        const { value } = e.target;
        e?.target?.setSelectionRange(value.length, value.length);
      }}
      autosize
      styles={{
        wrapper: {
          width: '100%',
        },
        input: {
          msOverflowStyle: 'none' /* Internet Explorer 10+ */,
          scrollbarWidth: 'none' /* Firefox */,
          WebkitScrollbar: { display: 'none' },
        },
        section: {
          bottom: 'var(--input-padding-y, 0rem)',
        },
      }}
      leftSectionPointerEvents="all"
      leftSectionWidth={renderFilterTrigger ? 80 : 40}
      leftSection={
        <Group align="end" justify="start" gap="xs" w="100%" h="100%" wrap="nowrap">
          <InputHelper {...infoIconProps} />
          {renderFilterTrigger && (
            <ActionIcon
              variant="subtle"
              color="gray"
              styles={{ root: { border: 'none' } }}
              onClick={() => {
                void setNavbarStatus('filters');
              }}
            >
              <IconFilterPlus />
            </ActionIcon>
          )}
        </Group>
      }
      rightSectionPointerEvents="all"
      rightSectionWidth={80}
      rightSection={
        <Group align="end" justify="end" gap="xs" w="100%" h="100%" wrap="nowrap">
          <ActionIcon
            variant="subtle"
            color={listening ? 'green' : 'gray'}
            disabled={!browserSupportsSpeechRecognition || loading}
            styles={{ root: { border: 'none' } }}
            onClick={() => {
              if (_value) {
                handleChange('');
                return;
              }
              return listening
                ? SpeechRecognition.stopListening()
                : SpeechRecognition.startListening({ interimResults: false });
            }}
          >
            {iconListening}
          </ActionIcon>
          <ActionIcon
            variant="subtle"
            disabled={(_value as string)?.replace(/\r\n|\r|\n/g, '').length < 3}
            loading={loading}
            styles={{ root: { border: 'none' } }}
            onClick={() => {
              void handleInputSubmit();
            }}
          >
            <IconSend2 />
          </ActionIcon>
        </Group>
      }
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();

          if ((_value as string)?.replace(/\r\n|\r|\n/g, '').length >= 3) {
            console.log({ _value });
            void handleInputSubmit();
          }
        }
      }}
      value={_value}
      onChange={(event) => handleChange(event.currentTarget.value)}
    />
  );
};
