import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAppAuth } from '../services';
import { AuthLoader } from '../components';
import { useHasAuthParams } from '../hooks';

export const SecuredRoute: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { isAuthenticated, isLoading, user, error } = useAppAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const hasAuthParams = useHasAuthParams();

  useEffect(() => {
    if ((isAuthenticated || error) && hasAuthParams) {
      const redirectLocation = (user?.state as { preAuthLocation: Location })?.preAuthLocation
        ? { ...(user?.state as { preAuthLocation: Location })?.preAuthLocation }
        : (location.state as { preAuthLocation: Location })?.preAuthLocation;
      window.history.replaceState({}, document.title, window.location.pathname);
      navigate(`${redirectLocation?.pathname}${redirectLocation?.search}${redirectLocation?.hash}`, {
        replace: true,
      });
    }
  }, [isAuthenticated, error]);
  //
  if (isLoading || hasAuthParams) {
    return <AuthLoader />;
  }

  return !isAuthenticated ? (
    <Navigate
      to="/auth"
      replace
      state={{ preAuthLocation: (user?.state as { preAuthLocation: Location })?.preAuthLocation ?? { ...location } }}
    />
  ) : (
    <>{children}</>
  );
};
