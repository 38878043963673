import React, { createContext, Key, useContext, useMemo } from 'react';
import { useUncontrolled } from '@mantine/hooks';
import { MantineTreeNode } from './MantineTreeNode';
import {
  arrAdd,
  arrDel,
  fillFieldNames,
  findItem,
  flattenTreeData,
  getCheckboxState,
  getChildrenKeysRecursive,
  getParentsKeysRecursive,
  parseCheckedKeys,
} from './utils';
import {
  BasicDataNode,
  MantineTreeBasicDataNodeType,
  MantineTreeCheckedKeysType,
  MantineTreeContextType,
  MantineTreeNodeControlledEventData,
  MantineTreePropsType,
} from './interface';

const MantineTreeContext = createContext<MantineTreeContextType>({} as MantineTreeContextType);

MantineTreeContext.displayName = 'MantineTreeContext';

export const useMantineTreeContext = (): MantineTreeContextType => {
  const context = useContext(MantineTreeContext);
  if (!context) {
    throw new Error('useMantineTreeContext must be used within MantineTreeContextProvider');
  }
  return context as MantineTreeContextType;
};

export const MantineTreeComponent = <TreeDataType extends BasicDataNode | MantineTreeBasicDataNodeType = BasicDataNode>(
  props: MantineTreePropsType<TreeDataType>,
) => {
  const {
    treeData,
    checkedKeys,
    selectedKeys,
    expandedKeys,
    defaultExpandedKeys,
    defaultCheckedKeys,
    defaultSelectedKeys,
    icon,
    switcherIcon,
    checkboxIcon,
    titleRender,
    onSelect,
    onExpand,
    onCheck,
    multiple = false,
    selectable = false,
    checkable = false,
    checkStrictly = false,
    blockNode = false,
  } = props;
  const fieldNames = fillFieldNames(props?.fieldNames);

  // console.log({ d: flattenTreeData<TreeDataType>(treeData, fieldNames.children) });

  const [uncontrolledExpandedKeys, setUncontrolledExpandedKeys] = useUncontrolled<Key[]>({
    value: expandedKeys,
    defaultValue: defaultExpandedKeys,
    finalValue: [],
    onChange: (controlledExpandedKeys, ...payload) => {
      onExpand?.(controlledExpandedKeys, payload[0] as MantineTreeNodeControlledEventData<TreeDataType>);
    },
  });
  const [uncontrolledSelectedKeys, setUncontrolledSelectedKeys] = useUncontrolled<Key[]>({
    value: selectable ? selectedKeys : undefined,
    defaultValue: selectable ? defaultSelectedKeys : undefined,
    finalValue: [],
    onChange: (controlledSelectedKeys, ...payload) => {
      onSelect?.(controlledSelectedKeys, payload[0] as MantineTreeNodeControlledEventData<TreeDataType>);
    },
  });
  const [uncontrolledCheckedKeys, setUncontrolledCheckedKeys] = useUncontrolled<MantineTreeCheckedKeysType>({
    value: checkable ? parseCheckedKeys(checkedKeys, treeData, checkStrictly, fieldNames) : undefined,
    defaultValue: checkable ? parseCheckedKeys(defaultCheckedKeys, treeData, checkStrictly, fieldNames) : undefined,
    finalValue: checkable
      ? parseCheckedKeys<TreeDataType>(
          flattenTreeData([...treeData], fieldNames.children)
            .filter((r) => r?.checked === true)
            .map((r) => r[fieldNames.key]),
          treeData,
          checkStrictly,
          fieldNames,
        )
      : undefined,
    onChange: (controlledCheckedKeys, ...payload) => {
      onCheck?.(controlledCheckedKeys, payload[0] as MantineTreeNodeControlledEventData<TreeDataType>);
    },
  });

  const handleSelectAction = useMemo(
    () => (key: Key, selected: boolean) => {
      const node = findItem(treeData, key, fieldNames);
      if (node === null) {
        return;
      }
      const eventObj: MantineTreeNodeControlledEventData<TreeDataType> = {
        node,
        selected,
      };

      if (!multiple) {
        const selectedKeys = [key];
        setUncontrolledSelectedKeys(selectedKeys, eventObj);
      } else {
        const newUncontrolledSelectedKeys = selected
          ? arrAdd(uncontrolledSelectedKeys, key)
          : arrDel(uncontrolledSelectedKeys, key);
        setUncontrolledSelectedKeys(newUncontrolledSelectedKeys, eventObj);
      }
    },
    [treeData, uncontrolledSelectedKeys, setUncontrolledSelectedKeys],
  );

  const handleExpandAction: MantineTreeContextType['onExpandAction'] = useMemo(
    () => (key, expanded) => {
      const node = findItem(treeData, key, fieldNames);
      if (node === null) {
        return;
      }

      const newUncontrolledExpandedKeys = expanded
        ? arrAdd(uncontrolledExpandedKeys, key)
        : arrDel(uncontrolledExpandedKeys, key);
      setUncontrolledExpandedKeys(newUncontrolledExpandedKeys, {
        node,
        expanded,
      });
    },
    [treeData, uncontrolledExpandedKeys, setUncontrolledExpandedKeys],
  );

  const handleCheckAction: MantineTreeContextType['onCheckAction'] = useMemo(
    () => (key, checked) => {
      const node = findItem(treeData, key, fieldNames);
      if (node === null) {
        return;
      }
      const eventObj: MantineTreeNodeControlledEventData<TreeDataType> = {
        node,
        checked,
      };

      if (checkStrictly) {
        const checkedKeys = Array.from(
          new Set(
            checked ? arrAdd(uncontrolledCheckedKeys.checked, key) : arrDel(uncontrolledCheckedKeys.checked, key),
          ),
        );
        const halfCheckedKeys = Array.from(new Set(arrDel(uncontrolledCheckedKeys.halfChecked, key)));

        setUncontrolledCheckedKeys({ checked: checkedKeys, halfChecked: halfCheckedKeys }, eventObj);
      } else {
        const childrenKeys = getChildrenKeysRecursive<TreeDataType>(node, fieldNames);
        const parentKeys = getParentsKeysRecursive<TreeDataType>(treeData, node[fieldNames.parentKey], fieldNames);
        const checkedKeys = checked
          ? Array.from(
              new Set([
                ...[...uncontrolledCheckedKeys.checked, ...childrenKeys].filter((r) => !parentKeys.includes(r)),
                ...parentKeys.filter((r) => {
                  const halfNode = findItem(treeData, r, fieldNames);
                  if (halfNode) {
                    const checkedKeys = [...uncontrolledCheckedKeys.checked, ...childrenKeys].filter(
                      (r) => !parentKeys.includes(r),
                    );
                    return getCheckboxState<TreeDataType>(halfNode, checkedKeys, fieldNames) === true;
                  }
                  return false;
                }),
              ]),
            )
          : Array.from(
              new Set(
                uncontrolledCheckedKeys.checked
                  .filter((key) => !childrenKeys.includes(key))
                  .filter((key) => !parentKeys.includes(key)),
              ),
            );

        const halfCheckedKeys = Array.from(
          new Set(
            [...uncontrolledCheckedKeys.halfChecked, ...parentKeys].filter((halfKey) => {
              const halfNode = findItem(treeData, halfKey, fieldNames);
              if (halfNode) {
                return getCheckboxState<TreeDataType>(halfNode, checkedKeys, fieldNames) === null;
              }
              return true;
            }),
          ),
        );
        setUncontrolledCheckedKeys({ checked: checkedKeys, halfChecked: halfCheckedKeys }, eventObj);
      }
    },
    [treeData, uncontrolledCheckedKeys, setUncontrolledCheckedKeys],
  );

  const context = useMemo<MantineTreeContextType>(
    () => ({
      selectedKeys: uncontrolledSelectedKeys,
      expandedKeys: uncontrolledExpandedKeys,
      checkedKeys: uncontrolledCheckedKeys,
      onExpandAction: handleExpandAction,
      onCheckAction: handleCheckAction,
      onSelectAction: handleSelectAction,
    }),
    [uncontrolledCheckedKeys, uncontrolledExpandedKeys, uncontrolledSelectedKeys, treeData],
  );

  return (
    <MantineTreeContext.Provider value={context}>
      {treeData.map((node) => (
        <MantineTreeNode<TreeDataType>
          node={node}
          fieldNames={fieldNames}
          key={node[fieldNames.key]}
          icon={icon}
          titleRender={titleRender}
          checkboxIcon={checkboxIcon}
          switcherIcon={switcherIcon}
          id={node[fieldNames.key]}
          checkable={checkable}
          selectable={selectable}
          blockNode={blockNode}
        />
      ))}
    </MantineTreeContext.Provider>
  );
};

export const MantineTree = <TreeDataType extends BasicDataNode | MantineTreeBasicDataNodeType = BasicDataNode>(
  props: MantineTreePropsType<TreeDataType>,
) => {
  return <>{props.treeData.length > 0 && <MantineTreeComponent<TreeDataType> {...props} />}</>;
};
