import { ActionIcon, Anchor, Flex, Group, List, Progress, ScrollArea, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import { IconChevronDown, IconChevronsUp, IconCopy } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import {
  KeywordSearchResultFragment,
  RatingDto,
  SemanticSearchResultFragment,
  useVoteForSearchResultMutation,
} from '../../../services/graphql/apolloAppClient';
import { VoteSection } from '../../../components';
import { useSearchHistoryEntryStore } from '../SearchHistoryEntryStoreProvider';
import { copySearchResultsToClipboard } from '../../../utils';

export interface ISearchResultListItemProps {
  number: number | string;
  content: SemanticSearchResultFragment | KeywordSearchResultFragment;
}

export const SearchResultListItem = (props: ISearchResultListItemProps) => {
  const { content, number } = props;
  // console.log({ url: content.uri });
  return (
    <Flex w="100%" gap={3} justify="flex-start" align="flex-start" direction="column" wrap="nowrap">
      <Flex w="100%" gap={3} justify="flex-start" align="flex-start" direction="row" wrap="nowrap" p="0">
        <Anchor
          component={content?.uri ? 'a' : 'span'}
          href={content.uri}
          target="_blank"
          rel="noreferrer"
          styles={{
            root: {
              padding: 0,
              margin: 0,
              border: 'none',
              outline: 'none',
              textWrap: 'wrap',
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              fontFamily: 'Roboto',
              fontWeight: 400,
              ...(content?.uri
                ? {}
                : {
                    cursor: 'default',
                    textDecoration: 'none',
                    color: 'inherit',
                  }),
            },
          }}
        >
          {content.__typename === 'SemanticSearchResultDto' ? content.answer : content.referenceCode}
        </Anchor>
        <ItemVoteView rating={content.rating} order={content.order} />
      </Flex>
      <ItemSnipetRender
        type={content.__typename}
        snippet={content.snippet}
        fullText={(content as SemanticSearchResultFragment)?.fullText}
        referenceCode={content.referenceCode}
        uri={content.uri}
      >
        {content.__typename === 'SemanticSearchResultDto' && (
          <Anchor
            component={content?.uri ? 'a' : 'span'}
            href={content.uri}
            rel="noreferrer"
            target="_blank"
            size="xs"
            styles={{
              root: {
                padding: 0,
                margin: 0,
                border: 'none',
                outline: 'none',
                textWrap: 'wrap',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                fontFamily: 'Roboto',
                fontWeight: 400,
                ...(content?.uri
                  ? {}
                  : {
                      cursor: 'default',
                      textDecoration: 'none',
                      color: 'inherit',
                    }),
              },
            }}
          >
            {content.referenceCode}
          </Anchor>
        )}
      </ItemSnipetRender>
      <Progress
        value={content.score * 100}
        // size="sm"
        styles={{
          root: { flexGrow: 1, width: '100%' },
        }}
      />
    </Flex>
  );
};

const ItemSnipetRender = ({
  snippet,
  fullText,
  type,
  uri,
  children,
  referenceCode,
}: {
  snippet: ISearchResultListItemProps['content']['snippet'];
  referenceCode: ISearchResultListItemProps['content']['referenceCode'];
  fullText?: SemanticSearchResultFragment['fullText'];
  uri?: ISearchResultListItemProps['content']['uri'];
  type: SemanticSearchResultFragment['__typename'] | KeywordSearchResultFragment['__typename'];
  children?: React.ReactNode;
}) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((val) => !val);
  const expandable = fullText && snippet !== fullText;
  const text = expanded && fullText ? fullText : snippet;

  return (
    <Stack gap={3}>
      <Text
        // size="xs"
        styles={{
          root: {
            padding: 0,
            margin: 0,
            border: 'none',
            outline: 'none',
            textWrap: 'wrap',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            fontFamily: 'Roboto',
            fontWeight: 400,
          },
        }}
        dangerouslySetInnerHTML={{ __html: text }}
      />

      <Group wrap="nowrap">
        {children}
        {expandable && (
          <ActionIcon variant="subtle" color="gray" onClick={toggleExpanded}>
            {expanded ? <IconChevronsUp /> : <IconChevronDown />}
          </ActionIcon>
        )}
        <ActionIcon
          variant="subtle"
          color="gray"
          onClick={() => {
            copySearchResultsToClipboard([{ text: fullText || snippet, uriLabel: referenceCode, uri }]).then(
              async () => {
                const clipboard = await navigator.clipboard.readText();
                notifications.show({
                  title: 'Result copied to clipboard',
                  autoClose: 2000,
                  message: (
                    <ScrollArea.Autosize mah={150}>
                      {/* eslint-disable-next-line react/no-danger */}
                      <div dangerouslySetInnerHTML={{ __html: clipboard }} />
                    </ScrollArea.Autosize>
                  ),
                });
              },
            );
          }}
        >
          <IconCopy />
        </ActionIcon>
      </Group>
    </Stack>
  );
};

const ItemVoteView = ({
  rating,
  order,
}: {
  rating: RatingDto;
  order: ISearchResultListItemProps['content']['order'];
}) => {
  const { searchHistoryEntryStore } = useSearchHistoryEntryStore();
  const [voteForSearchResult, voteForSearchResultStore] = useVoteForSearchResultMutation({
    onCompleted: (res) => {
      void searchHistoryEntryStore.refetch();
    },
    onError: (error, clientOptions) => {
      console.log({ error });
    },
  });
  return (
    <VoteSection
      styles={{
        root: { marginLeft: 'auto' },
      }}
      onVote={(vote) =>
        voteForSearchResult({
          variables: {
            input: {
              vote,
              order,
              queryId: searchHistoryEntryStore?.data?.query?.id,
            },
          },
        })
      }
      rating={rating}
    />
  );
};
