import { useCallback, useEffect, useRef, useState } from 'react';
import { useInterval } from '@mantine/hooks';
import { timeSince } from '../utils';

export interface IUseTimeSinceTimeoutProps {
  date: string | Date;
  delay?: number;
}
export const useTimeSinceTimeout = (props: IUseTimeSinceTimeoutProps): string | void => {
  const { date, delay = 60000 } = props;
  const [value, setValue] = useState(timeSince(date));
  const interval = useInterval(() => setValue(timeSince(date)), delay);

  useEffect(() => {
    interval.start();
    return interval.stop;
  }, [date]);

  return value;
};
