import React from 'react';
import { ApolloGraphqlAppApiClientProvider } from './ApolloGraphqlAppApiClientProvider';

import { UrqlGraphqlAppApiClientProvider } from './UrqlGraphqlAppApiClientProvider';
import { AppAuthenticationServiceProvider } from './AppAuthenticationServiceProvider';

export const AppServicesProvider = ({ children }) => {
  return (
    <AppAuthenticationServiceProvider>
      <ApolloGraphqlAppApiClientProvider>
        <UrqlGraphqlAppApiClientProvider>{children}</UrqlGraphqlAppApiClientProvider>
      </ApolloGraphqlAppApiClientProvider>
    </AppAuthenticationServiceProvider>
  );
};
