import React from 'react';
import { Flex, Text } from '@mantine/core';
import { CONSTANTS } from '../../config';
import { useAppTheme } from '../../themes';
import { EgwLogo } from '../../components';

export const CopyrightSection = () => {
  const now = new Date();
  const { isMobileLayout } = useAppTheme();
  return (
    <Flex h="100%" gap={3} justify="flex-start" align="center" direction="row" wrap="nowrap">
      <Flex h="100%" justify="center" align="center" direction="column" wrap="nowrap">
        <EgwLogo color="white" size="appTitle" />
      </Flex>
      <Flex h="100%" justify="center" align="flex-start" direction="column" wrap="nowrap">
        <Text span lineClamp={1} size="copyrights">
          Copyright, © {now.getFullYear()}. {CONSTANTS.APP_COPYRIGHT}.{/* <br /> */}
        </Text>
        <Text span size="copyrights" lineClamp={isMobileLayout ? 2 : 1}>
          12501 Old Columbia Pike, Silver Spring, MD 20904, USA | Ph# 1.301.680.6540.
        </Text>
      </Flex>
    </Flex>
  );
};
