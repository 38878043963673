import React from 'react';
import { AppShell, Flex, MantineProvider, Portal, rem } from '@mantine/core';
import { CopyrightSection } from './CopyrightSection';
import { ContactUsSection } from './ContactUsSection';
import { useAppTheme } from '../../themes';

export const FooterLayoutComponent = () => {
  return (
    <MantineProvider
      theme={{
        fontFamily: 'Roboto, sans-serif',
      }}
    >
      <Flex h="100%" w="100%" gap="sm" justify="space-between" align="center" direction="row" wrap="nowrap">
        <CopyrightSection />
        <ContactUsSection />
      </Flex>
    </MantineProvider>
  );
};
export const FooterLayout: React.FC<{ isNavbar?: boolean }> = (props) => {
  const { isNavbar = false } = props;
  const { isMobileLayout, mantineTheme: theme } = useAppTheme();

  if (isNavbar) {
    if (isMobileLayout) {
      return (
        <AppShell.Section
          w="100%"
          px="var(--mantine-spacing-xs)"
          py={rem(5)}
          h={50}
          style={{
            backgroundColor: theme.colors.egwPrimary[9],
            color: '#FFFFFF',
          }}
        >
          <FooterLayoutComponent />
        </AppShell.Section>
      );
    }
    return null;
  }

  if (isMobileLayout) {
    return null;
  }
  return (
    <AppShell.Footer>
      <FooterLayoutComponent />
    </AppShell.Footer>
  );
};
