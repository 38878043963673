import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import { useLocation } from 'react-router-dom';
import { useGetQueryHistoryQuery } from '../services/graphql/apolloAppClient';
import { NavBarActiveTabType } from './NavbarLayout';

export interface UseAppLayoutResultType {
  navbarStatus: NavBarActiveTabType;
  withNavBar: boolean;
  // setNavbarStatus: ReturnType<typeof useLocalStorage<NavBarActiveTabType>>[1];
  setNavbarStatus: React.Dispatch<React.SetStateAction<NavBarActiveTabType>>;
}

export const AppLayoutContext = createContext<UseAppLayoutResultType | undefined>(undefined);
AppLayoutContext.displayName = 'AppLayoutContext';

export const useAppLayout = (): UseAppLayoutResultType => {
  const context = useContext(AppLayoutContext);
  if (!context) {
    console.warn('useLayoutStoreContext must be used within LayoutStoreProvider');
  }
  return context as UseAppLayoutResultType;
};

export const AppLayoutProvider = ({ children }) => {
  const location = useLocation();
  // const [navbarStatus, setNavbarStatus] = useLocalStorage<NavBarActiveTabType>({
  //   key: 'navbarStatus',
  //   defaultValue: 'history',
  // });
  const [navbarStatus, setNavbarStatus] = useState<NavBarActiveTabType>('filters');
  const [withNavBar, setWithNavBar] = useState(location.pathname !== '/auth');

  useEffect(() => {
    setWithNavBar(!['/auth', '/auth/redirect'].includes(location.pathname));
  }, [location.pathname]);

  const appLayoutContextObject: UseAppLayoutResultType = useMemo(
    () => ({
      navbarStatus,
      setNavbarStatus,
      withNavBar,
    }),
    [navbarStatus, withNavBar],
  );

  return <AppLayoutContext.Provider value={appLayoutContextObject}>{children}</AppLayoutContext.Provider>;
};
