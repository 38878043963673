import React from 'react';
import { AppShell, Container } from '@mantine/core';
import { Outlet } from 'react-router-dom';

const AuthLayoutComponent = () => {
  return (
    <AppShell.Main>
      <Container h="100%" maw={900} p={0}>
        <Outlet />
      </Container>
    </AppShell.Main>
  );
};

export const AuthLayout = () => {
  return <AuthLayoutComponent />;
};
