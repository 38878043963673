import React from 'react';
import { AppShell, Flex, Group } from '@mantine/core';
import { AppLogoSection } from './AppLogoSection';
import { AppSettingsSection } from './AppSettingsSection';
import { TitleSection } from './TitleSection';

export const HeaderLayout: React.FC = () => {
  return (
    <AppShell.Header>
      <Flex h="100%" gap={3} justify="space-between" align="center" direction="row" wrap="nowrap">
        <Flex
          gap="var(--mantine-spacing-xs)"
          h="100%"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="nowrap"
        >
          <Group id="header-left-actions--portal-wrapper" gap="var(--mantine-spacing-xs)" wrap="nowrap" />
          <AppLogoSection />
        </Flex>
        <TitleSection />
        <AppSettingsSection />
      </Flex>
    </AppShell.Header>
  );
};
