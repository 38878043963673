import React from 'react';
import { cacheExchange, Client as UrqlClient, fetchExchange, Provider as UrqlProvider } from 'urql';
import { CONSTANTS } from '../config';
import { useAppAuth } from './AppAuthenticationServiceProvider';

export const UrqlGraphqlAppApiClientProvider = ({ children }) => {
  const { user } = useAppAuth();

  const client = new UrqlClient({
    url: `${CONSTANTS.APP_API_URL}/graphql`,
    exchanges: [cacheExchange, fetchExchange],
    fetchSubscriptions: true,
    requestPolicy: 'network-only',
    fetchOptions: () => ({
      headers: {
        authorization: `Bearer ${user?.access_token}`,
        Accept: 'multipart/mixed; deferSpec=20220824',
      },
    }),

    // cache: new InMemoryCache({
    //     // typePolicies: {
    //     //
    //     // },
    //     possibleTypes: {
    //         HistoryEntry: ["KeywordHistoryEntryDto", "SemanticHistoryEntryDto"],
    //     }
    // }),
    // connectToDevTools: process.env.NODE_ENV === 'development',
    // queryDeduplication: true,
  });

  return <UrqlProvider value={client}>{children}</UrqlProvider>;
};
