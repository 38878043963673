import { Key } from 'react';
import {
  BasicDataNode,
  FieldNames,
  MantineTreeBasicDataNodeType,
  MantineTreeCheckedKeysType,
  MantineTreeCheckedStateType,
} from './interface';

export const fillFieldNames = (fieldNames?: FieldNames): Required<FieldNames> => {
  const { title, key, children, parentKey, childCount } = fieldNames || {};

  return {
    title: title || 'title',
    key: key || 'key',
    children: children || 'children',
    parentKey: parentKey || 'parentKey',
    childCount: childCount || 'childCount',
  };
};

export const arrDel = (list: Key[], value: Key) => {
  if (!list) return [];
  const clone = list.slice();
  const index = clone.indexOf(value);
  if (index >= 0) {
    clone.splice(index, 1);
  }
  return clone;
};

export const arrAdd = (list: Key[], value: Key) => {
  const clone = (list || []).slice();
  if (clone.indexOf(value) === -1) {
    clone.push(value);
  }
  return clone;
};

export const getCheckboxState = <TreeDataType extends MantineTreeBasicDataNodeType = BasicDataNode>(
  item: TreeDataType,
  checked: Key[],
  fieldNames: Required<FieldNames>,
): boolean | null => {
  if (checked.includes(item[fieldNames.key])) {
    return true;
  }
  if (item[fieldNames.children] && item[fieldNames.children].length) {
    const childrenState = item[fieldNames.children].map((r: TreeDataType) =>
      getCheckboxState<TreeDataType>(r, checked, fieldNames),
    );
    if (childrenState.every((r: boolean) => r)) {
      return true;
    }
    if (childrenState.every((r: boolean) => !r)) {
      return false;
    }
    return null;
  }
  return false;
};

export const findItem = <TreeDataType extends MantineTreeBasicDataNodeType = BasicDataNode>(
  data: TreeDataType[],
  id: Key,
  fieldNames: Required<FieldNames>,
): TreeDataType | null => {
  const { key, children } = fieldNames;
  for (const item of data) {
    if (item[key] === id) {
      return item;
    }
    if (item[children] && item[children].length) {
      const result = findItem(item[children], id, fieldNames);
      if (result) {
        return result as unknown as TreeDataType;
      }
    }
  }
  return null;
};
export const getChildrenKeysRecursive = <TreeDataType extends MantineTreeBasicDataNodeType = BasicDataNode>(
  item: TreeDataType,
  fieldNames: Required<FieldNames>,
): Key[] => {
  const children = item[fieldNames.children] || [];
  return [
    item[fieldNames.key],
    ...children.flatMap((child: TreeDataType) => getChildrenKeysRecursive(child, fieldNames)),
  ];
};

export const flattenTreeData = <TreeDataType extends MantineTreeBasicDataNodeType = BasicDataNode>(
  treeData: TreeDataType[],
  childrenFieldKey?: Required<FieldNames>['children'],
): TreeDataType[] => {
  const ChildrenFieldKey = childrenFieldKey || 'children';
  return treeData.flatMap((r) => {
    const children = [...(r?.[ChildrenFieldKey] ? r[ChildrenFieldKey] : [])];
    const record = { ...r };
    delete record?.[ChildrenFieldKey];
    return [record, ...flattenTreeData<TreeDataType>(children || [], ChildrenFieldKey)];
  });
};

export const setFinalValue = <T = any,>(value: T, callback?: (value: T, ...payload: any[]) => void) => {
  callback?.(value);
  return value;
};
export const getParentsKeysRecursive = <TreeDataType extends MantineTreeBasicDataNodeType = BasicDataNode>(
  treeData: TreeDataType[],
  key: Key,
  fieldNames: Required<FieldNames>,
): Key[] => {
  const parentKeys: Key[] = [];
  let parent = findItem(treeData, key, fieldNames);
  while (parent) {
    parentKeys.push(parent[fieldNames.key]);
    parent = findItem(treeData, parent[fieldNames.parentKey], fieldNames);
  }
  return parentKeys;
};

export const parseCheckedKeys = <TreeDataType extends MantineTreeBasicDataNodeType = BasicDataNode>(
  keys?: MantineTreeCheckedStateType,
  treeData?: TreeDataType[],
  checkStrictly?: boolean,
  fieldNames?: Required<FieldNames>,
):
  | {
      halfChecked: Key[];
      checked: Key[];
    }
  | undefined => {
  if (keys === undefined) {
    return undefined;
  }

  if (Array.isArray(keys) && treeData && fieldNames) {
    // [Legacy] Follow the api doc
    if (checkStrictly) {
      return {
        checked: Array.from(new Set(keys)),
        halfChecked: [],
      };
    }
    const halfCheckedKeys: Key[] = [];
    const childrenKeys: Key[] = [];
    const filteredCheckedKeys: Key[] = [
      ...keys.filter((r) => {
        const rNode = findItem(treeData, r, fieldNames);
        if (rNode) {
          const parentKeys = getParentsKeysRecursive<TreeDataType>(
            treeData,
            rNode[fieldNames.parentKey],
            fieldNames,
          ).filter((r) => !keys.includes(r));
          const rChildrenKeys = getChildrenKeysRecursive<TreeDataType>(rNode, fieldNames).filter(
            (childKey) => childKey !== r || !keys.includes(childKey),
          );
          childrenKeys.push(...rChildrenKeys);
          const checkState = getCheckboxState<TreeDataType>(rNode, keys, fieldNames);
          if (checkState === true) {
            halfCheckedKeys.push(...parentKeys);
          }
          return checkState;
        }
        return false;
      }),
    ];
    const checkedKeys = Array.from(new Set([...filteredCheckedKeys, ...childrenKeys]));
    return {
      checked: checkedKeys,
      halfChecked: halfCheckedKeys,
    };
  }
  return {
    checked: (keys as MantineTreeCheckedKeysType).checked || [],
    halfChecked: (keys as MantineTreeCheckedKeysType).halfChecked || [],
  };
};

export const parseMantineTreeData = <TreeDataType extends MantineTreeBasicDataNodeType = BasicDataNode>(
  flattenedTreeData: TreeDataType[],
  fieldNames?: FieldNames,
): TreeDataType[] => {
  const { key, children, parentKey, childCount } = fillFieldNames(fieldNames);
  const ID_KEY = key;
  const TREE_ID_KEY = key;
  const PARENT_KEY = parentKey;
  const CHILDREN_KEY = children;
  const CHILD_COUNT_KEY = childCount;
  const hashTable = Object.create(null);
  const dataTree: TreeDataType[] = [];
  if (flattenedTreeData) {
    flattenedTreeData.forEach((aData) => {
      hashTable[aData[ID_KEY]] = {
        ...aData,
        ...(!aData[TREE_ID_KEY] || aData[CHILD_COUNT_KEY] > 0 ? { [TREE_ID_KEY]: aData[ID_KEY] } : {}),
        // ...(aData[CHILD_COUNT_KEY] > 0 ? { [`${CHILDREN_KEY}`]: [] } : {}),
        [`${CHILDREN_KEY}`]: [],
      };
    });

    flattenedTreeData.forEach((aData) => {
      if (aData[PARENT_KEY] && hashTable[aData[PARENT_KEY]]?.[CHILDREN_KEY]) {
        hashTable[aData[PARENT_KEY]][CHILDREN_KEY].push(hashTable[aData[ID_KEY]]);
      } else {
        // @ts-ignore
        dataTree.push(hashTable[aData[ID_KEY]]);
      }
    });
  }
  return dataTree;
};
