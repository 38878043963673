import React from 'react';
import { Text, Box, rem } from '@mantine/core';
import { Link } from 'react-router-dom';
import { EgwLogo } from '../../components';

export const AppLogoSection = () => {
  return (
    <Box
      component={Link}
      to="/"
      // h="100%"
      style={{
        textDecoration: 'none',
        alignSelf: 'center',
        alignItems: 'baseline',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
      }}
    >
      <EgwLogo color="white" size="appTitle" />
      <Text
        component="span"
        size="appTitle"
        styles={{
          root: {
            fontFamily: 'Nova Round, sans-serif',
            fontWeight: 400,
            color: 'white',
            lineHeight: 0,
          },
        }}
      >
        Search
      </Text>
    </Box>
  );
};
