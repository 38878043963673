import React from 'react';
import { Flex, Loader, Text } from '@mantine/core';

export const AuthLoader = () => {
  return (
    <Flex h="calc(100vh - 150px)" w="100%" gap="md" justify="center" align="center" direction="row" wrap="nowrap">
      <Loader size={30} />
      <Text style={{ textAlign: 'center' }}>Authorization. Please wait...</Text>
    </Flex>
  );
};
