import React from 'react';
import { getFontSize, rem } from '@mantine/core';

interface EgwSourceProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const EgwSource: React.FC<EgwSourceProps> = ({ size, style, ...others }) => {
  return (
    <svg
      height="24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      stroke="none"
      viewBox="0 0 490 490"
      style={{ width: getFontSize(size), height: getFontSize(size), ...style }}
      {...others}
    >
      <path
        d="M230.765,367.308c18.675,0,36.048-5.591,50.626-15.118l46.327,47.416l21.891-21.412l-45.686-46.76
		c12.296-15.751,19.698-35.5,19.698-56.982c0-51.198-41.658-92.856-92.856-92.856c-51.198,0-92.856,41.658-92.856,92.856
		C137.909,325.65,179.567,367.308,230.765,367.308z M230.765,212.219c34.316,0,62.233,27.917,62.233,62.233
		c0,34.316-27.917,62.233-62.233,62.233c-34.316,0-62.233-27.917-62.233-62.233C168.532,240.136,196.449,212.219,230.765,212.219z"
      />
      <path
        d="M434.948,98.194h-20.868c-5.485-7.068-18.384-29.641,0.796-67.571h11.983V0H119.334C83.888,0,55.052,28.904,55.052,64.416
		v342.806c0,28.993,8,51.093,23.767,65.687C95.641,488.46,115.483,490,122.272,490c1.286,0,2.101-0.045,2.34-0.075h310.336V98.194z
		 M119.334,30.623h262.1c-1.518,3.974-2.77,7.822-3.783,11.559h-169.02v30.623h165.511c0.532,9.829,2.604,18.348,5.356,25.39
		H119.334c-18.556,0-33.659-15.147-33.659-33.778C85.675,45.785,100.777,30.623,119.334,30.623z M404.325,459.302l-280.902,0.045
		c-0.127-0.045-13.517,0.598-23.805-8.927c-9.256-8.553-13.943-23.087-13.943-43.198V119.263c9.794,6.056,21.33,9.555,33.659,9.555
		h284.991V459.302z"
      />
    </svg>
  );
};
