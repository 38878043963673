import React, { useEffect } from 'react';
import { Button, Flex, Text, Title } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppAuth } from '../services';
import { useTitle } from '../hooks/useTitle';
import { AuthLoader } from '../components';

export const AuthPage = (props) => {
  const { isAuthenticated, user, signinRedirect, removeUser, isLoading, error } = useAppAuth();
  useTitle('AuthPage');
  const location = useLocation();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (user && hasAuthParams()) {
  //     const redirectLocation = (user?.state as { preAuthLocation: Location })?.preAuthLocation
  //       ? { ...(user?.state as { preAuthLocation: Location })?.preAuthLocation }
  //       : (location.state as { preAuthLocation: Location })?.preAuthLocation;
  //     // user.session_state = null;
  //     // navigate(`${redirectLocation?.pathname}${redirectLocation?.search}${redirectLocation?.hash}`, {
  //     //   replace: true,
  //     // });
  //   }
  // }, [user]);

  if (isLoading) {
    return <AuthLoader />;
  }

  return (
    <Flex justify="center" direction="column" gap={25} align="center" h="100%">
      <Title style={{ textAlign: 'center' }}>New EGW Writings Search Beta</Title>
      {error?.message && (
        <Text c="red" style={{ textAlign: 'center' }}>
          Oops... {error.message}
        </Text>
      )}
      <Button
        variant="subtle"
        size="md"
        onClick={() => {
          // setPreAuthLocation(location.state?.preAuthLocation ?? location);
          const preAuthUrl = (location.state as { preAuthLocation: Location })?.preAuthLocation;
          if (!isAuthenticated) {
            return void signinRedirect({
              redirectMethod: 'replace',
              state: {
                preAuthLocation: location.state?.preAuthLocation ?? location,
              },
            });
          }
          return void removeUser();
          //   .then(() =>
          // signinRedirect({
          //   redirectMethod: 'replace',
          //   // state: {
          //   //   preAuthLocation: state?.preAuthLocation ?? { ...location },
          //   // },
          // }),
          // );
        }}
      >
        {isAuthenticated ? 'Sign out' : 'Sign in with EGW Account'}
      </Button>
      {isAuthenticated && (
        <>
          <Button
            variant="subtle"
            size="md"
            onClick={() => {
              navigate(
                location.state?.preAuthLocation
                  ? `${location.state?.preAuthLocation?.pathname}${location.state?.preAuthLocation?.search}${location.state?.preAuthLocation?.hash}`
                  : '/',
              );
            }}
          >
            Return to previous page
          </Button>
        </>
      )}
    </Flex>
  );
};
