import { v4 as uuidV4 } from 'uuid';
import { AxiosError } from 'axios';
import { ContextType } from 'react';
import { GraphQLErrors, NetworkError } from '@apollo/client/errors';
import { ServerError } from '@apollo/client/link/utils';
import { ServerParseError } from '@apollo/client/link/http';
import { GraphQLError } from 'graphql/error';
import { Flex, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';

interface IOptions<F = any> {
  context?: ContextType<any>;
}

const parseErrors = (errors) => {
  const newMsg = Object.entries(errors)
    .filter((i: any) => !['status', 'traceId', 'type', 'extensions', 'path', 'locations'].includes(i[0]))
    .map((i: any, key) => {
      if (Array.isArray(errors[i[0]])) {
        return errors[i[0]].map((m, key) => {
          return (
            <Flex key={key} gap={5} wrap="nowrap" justify="left">
              <Text>{i[0]}</Text>
              <div style={{ wordWrap: 'break-word' }}>{m}</div>
            </Flex>
          );
        });
      }
      return (
        <Flex key={key} gap={5} wrap="nowrap" justify="left">
          <Text>{i[0]}</Text>
          <div style={{ wordWrap: 'break-word' }}>{i[1]}</div>
        </Flex>
      );
    });
  return newMsg;
};

const processError = (context, status, message, errorKey) => {
  const [logCode, strCode] = !status ? ['[500]', '500'] : [status.toString(), status.toString()];
  if (context) {
    context(strCode);
  }
  notifications.show({
    color: 'red',
    title: `Request error status: ${status}`,
    message: (
      <Flex gap={5} align="left" justify="left" style={{ flexDirection: 'column' }}>
        {message}
      </Flex>
    ),
    key: errorKey,
  });
  return Promise.resolve(`${logCode}: ${message}`).catch((e) => {
    console.log(e);
  });
};

export const axiosErrorsHandler = (error: AxiosError, options: IOptions = {}): any => {
  const CONTEXT = options.context || undefined;
  const errorKey = uuidV4();

  if (!error?.response) {
    console.error(`API: 0: No Response`);
    return processError(CONTEXT, 0, 'No Response', errorKey);
  }

  const { message: msg } = error;
  const { data, status } = error.response;

  if (typeof data === 'string') {
    console.error(`API: ${status}: ${data}`);
    return processError(CONTEXT, status, `${data}`, errorKey);
  }

  // if (status && [401, 403].includes(status)) {
  //   console.log(status);
  //   window.location.replace(`/${status}`);
  //   return;
  // }

  // else - data is string case
  if (data) {
    // @ts-ignore
    const errors = data?.errors || data;
    const newMsg = parseErrors(errors);
    return processError(CONTEXT, status, newMsg, errorKey);
  }

  if (parseInt(String(status), 10) === 304) {
    return processError(CONTEXT, status, `${status}: Not Modified!`, errorKey);
  }
  console.log(`${status}: ${error.message}`);
  return processError(CONTEXT, status, error.message, errorKey);
};

export const apolloErrorsHandler = (
  error: NetworkError | GraphQLErrors | GraphQLError,
  options: IOptions = {},
): any => {
  const CONTEXT = options.context || undefined;
  const errorKey = uuidV4();

  if (error instanceof GraphQLError) {
    const newMsg = parseErrors(error) || [];
    const status = error.extensions?.code || 0;
    return processError(CONTEXT, status, newMsg, errorKey);
  }

  if (error && Array.isArray(error as GraphQLErrors)) {
    const newMsg = (error as GraphQLErrors)?.map((e) => parseErrors(e)) || [];
    const status = (error as GraphQLErrors)?.[0]?.extensions?.code || 0;
    return processError(CONTEXT, status, newMsg, errorKey);
  }

  if (error && (error as ServerError)?.result) {
    const {
      result: { errors },
      statusCode,
    } = error as any;
    const newMsg = errors?.map((e) => parseErrors(e)) || [];
    return processError(CONTEXT, statusCode || 0, newMsg, errorKey);
  }

  if (error && (error as ServerParseError)?.response) {
    const {
      bodyText,
      response: { status },
    } = error as ServerParseError;

    if (typeof bodyText === 'string') {
      console.error(`API: ${status}: ${bodyText}`);
      return processError(CONTEXT, status, `${bodyText}`, errorKey);
    }

    if (parseInt(String(status), 10) === 304) {
      return processError(CONTEXT, status, `${status}: Not Modified!`, errorKey);
    }
    console.log(`${status}: error`);
    return processError(CONTEXT, status, 'error', errorKey);
  }
  return Promise.resolve(error).catch((e) => {
    console.log(e);
  });
};
