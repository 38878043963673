import React from 'react';
import { Flex } from '@mantine/core';
import { SearchSettingsMenu } from './SearchSettingsMenu';
import { SearchTypeFilter } from './SearchTypeFilter';
import { useAppTheme } from '../../themes';

export const SearchFilters = () => {
  const { isMobileLayout, isTargetWidthLayout } = useAppTheme();
  return (
    <Flex
      w="100%"
      gap={10}
      justify={isMobileLayout || isTargetWidthLayout ? 'center' : 'space-between'}
      align="center"
      direction="row"
      wrap="wrap"
    >
      <SearchTypeFilter />
      {/* <SearchSettingsMenu /> */}
    </Flex>
  );
};
