import React from 'react';
import { Alert, AlertProps, Text } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import { useElementSize } from '@mantine/hooks';
import { useChatStore } from './ChatStoreProvider';

export const ChatAlertSection = () => {
  const { editableUserMessage, setEditableUserMessage } = useChatStore();
  const { ref: alertRef, width: alertWidth } = useElementSize();

  if (!editableUserMessage) {
    return null;
  }
  return (
    <Alert
      pt={10}
      pb={10}
      ref={alertRef}
      withCloseButton
      variant="default"
      w="100%"
      icon={<IconPencil />}
      title={
        <Text truncate="end" size="sm">
          <Text fw={700} component="span">{`Edit ${editableUserMessage.type} message: `}</Text>
          {editableUserMessage.text}
        </Text>
      }
      onClose={() => {
        setEditableUserMessage(null);
      }}
      styles={{
        root: {
          // border: 'none',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottom: 'none',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        title: {
          width: alertWidth - 100,
        },
        message: {
          width: alertWidth - 100,
        },
      }}
    />
  );
};
