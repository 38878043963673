import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CONSTANTS } from '../config';

export const useTitle = (title = '') => {
  const location = useLocation();
  const path = location.pathname.replace(/\//g, ' ');
  title = `${CONSTANTS.APP_TITLE}${title ? ` - ${title}` : path && path !== ' ' ? ` - ${path}` : ''}`;
  useEffect(() => {
    const oldTitle = document.title;
    if (title && oldTitle !== title) {
      document.title = title;
    } else if (oldTitle !== title) {
      document.title = title;
    }
    return () => {
      // document.title = oldTitle;
    };
  }, [title, location.pathname]);
};
