import React from 'react';
import { Accordion, Flex, Loader, ScrollArea, Title } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { MantineTree } from '../../../../components';
import { FoldersTreeFilterNodeType, useFoldersStore } from './FoldersStoreProvider';

export const FoldersFilter: React.FC<{ height?: number }> = (props) => {
  const { folders, callback, foldersTree, checkedFolders, setCheckedFolders } = useFoldersStore();
  const { height } = props;
  return (
    <Accordion.Item key="Folders" value="Folders">
      <Accordion.Control icon={<></>}>Folders</Accordion.Control>
      <Accordion.Panel>
        {folders?.loading ? (
          <Flex w="100%" h={50} gap={5} justify="center" align="center" wrap="nowrap">
            <Loader variant="dots" />
          </Flex>
        ) : (
          <MantineTree<FoldersTreeFilterNodeType>
            checkedKeys={checkedFolders}
            checkable
            fieldNames={{
              key: 'id',
              title: 'title',
              children: 'children',
              parentKey: 'parentId',
            }}
            treeData={foldersTree}
            onCheck={(checkedKeys) => {
              setCheckedFolders(
                Array.isArray(checkedKeys) ? (checkedKeys as string[]) : (checkedKeys.checked as string[]),
              );
              callback?.(Array.isArray(checkedKeys) ? (checkedKeys as string[]) : (checkedKeys.checked as string[]));
            }}
          />
        )}
      </Accordion.Panel>
    </Accordion.Item>
  );
};
