import React from 'react';
import { Burger, Portal } from '@mantine/core';
import { useAppLayout } from '../AppLayoutProvider';
import { useAppTheme } from '../../themes';

export const NavbarBurger = () => {
  const { isMobileLayout } = useAppTheme();
  const { navbarStatus, setNavbarStatus, withNavBar } = useAppLayout();
  const label = !navbarStatus ? 'Close navigation' : 'Open navigation';
  if (!withNavBar) {
    return null;
  }
  return (
    <Portal target="#header-left-actions--portal-wrapper">
      <Burger
        size="sm"
        color="white"
        opened={navbarStatus !== null}
        onClick={() => setNavbarStatus((prevState) => (!prevState ? 'history' : null))}
        aria-label={label}
        hiddenFrom={isMobileLayout ? undefined : 'sm'}
      />
    </Portal>
  );
};
