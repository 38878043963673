import React from 'react';
import { Flex } from '@mantine/core';
import { IconInfoCircle, IconMessageChatbot, IconSearch } from '@tabler/icons-react';
import { useDebouncedCallback } from '@mantine/hooks';
import { v4 as uuidV4 } from 'uuid';
import { useForm } from '@mantine/form';
import { SearchInput } from '../../../components';
import { useSearchHistoryEntryStore } from '../SearchHistoryEntryStoreProvider';
import { useSearchHistoryEntrySections } from '../SearchHistoryEntrySections';

export const NewSearchInput = () => {
  const { onSearch, searchHistoryEntryStore, sendSearchQueryStore, sendSearchQuery, searchForm } =
    useSearchHistoryEntryStore();

  const onValuesChange = useDebouncedCallback(async (values: { query: string }) => {
    searchForm.setFieldValue('query', values.query);
  }, 50);
  const newSearchForm = useForm<{ query: string }>({
    name: 'newSearchForm',
    initialValues: {
      query: searchHistoryEntryStore.data?.query?.query || '',
    },
    validate: {
      query: (value) => {
        const isValid = value.replace(/\r\n|\r|\n/g, '').length >= 3;
        return isValid ? null : 'Name must have at least 3 letters';
      },
    },
    validateInputOnBlur: false,
    onValuesChange,
  });

  const { setActiveTab, withChat, activeTab } = useSearchHistoryEntrySections();
  const icon = !withChat ? <IconInfoCircle /> : activeTab === 'searchResults' ? <IconMessageChatbot /> : <IconSearch />;

  const handleSearch = useDebouncedCallback(async (value: string) => {
    if (!searchForm.validate().hasErrors) {
      await sendSearchQuery({
        variables: {
          input: { ...searchForm.values, id: uuidV4() },
        },
      }).catch((error) => {
        console.warn('Search error', { error });
      });
    }
  }, 200);

  const onSpeechRecognitionChange = useDebouncedCallback(async (value: string) => {
    if (value.length > 3) {
      await sendSearchQuery({
        variables: {
          input: { ...searchForm.values, id: uuidV4(), query: value },
        },
      }).catch((error) => {
        console.warn('Search error', { error });
      });
    }
  }, 500);

  return (
    <Flex w="100%" gap={5} justify="space-between" align="center" direction="row">
      <SearchInput
        key={newSearchForm.key('query')}
        {...newSearchForm.getInputProps('query')}
        placeholder={searchHistoryEntryStore.data?.query?.query || 'Input you search query ...'}
        onInputSubmit={handleSearch}
        loading={sendSearchQueryStore.loading}
        infoIconProps={{
          children: icon,
          onClick: () =>
            withChat && setActiveTab((prevState) => (prevState === 'searchResults' ? 'chat' : 'searchResults')),
        }}
        onSpeechRecognitionChange={onSpeechRecognitionChange}
      />
    </Flex>
  );
};
