import React, { useMemo } from 'react';
import { Box, Checkbox, CheckboxProps, Flex, Group, Stack } from '@mantine/core';
import { useMantineTreeContext } from './MantineTree';
import { MantineTreeSwitcherActionIcon } from './MantineTreeSwitcherActionIcon';
import { BasicDataNode, MantineTreeBasicDataNodeType, MantineTreeNodePropsType } from './interface';

export const MantineTreeNode = <TreeDataType extends MantineTreeBasicDataNodeType = BasicDataNode>(
  props: MantineTreeNodePropsType<TreeDataType>,
) => {
  const {
    node,
    id,
    fieldNames,
    icon: Icon,
    titleRender,
    switcherIcon,
    checkboxIcon,
    checkable: checkDirectly,
    selectable: selectDirectly,
    blockNode,
  } = props;
  const { expandedKeys, checkedKeys, selectedKeys, onExpandAction, onCheckAction, onSelectAction } =
    useMantineTreeContext();
  const isIndeterminate = checkedKeys?.halfChecked?.includes(node[fieldNames.key]);
  const isChecked = checkedKeys?.checked?.includes(node[fieldNames.key]);
  const isExpanded = expandedKeys?.includes(node[fieldNames.key]);
  const isSelected = selectedKeys?.includes(node[fieldNames.key]) ?? false;
  const isLeaf = !node[fieldNames.children]?.length;

  const handleCheckChange: CheckboxProps['onChange'] = (event) => {
    onCheckAction(node[fieldNames.key], event.currentTarget.checked);
  };

  const handleSelectChange: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (selectDirectly && [undefined, true].includes(node?.selectable)) {
      event.preventDefault();
      onSelectAction(node[fieldNames.key], !isSelected);
    }
  };

  const selector = (
    <Flex wrap="nowrap" align="center" m={5} p={0} gap={0}>
      <MantineTreeSwitcherActionIcon
        id={id}
        switcherIcon={switcherIcon}
        isLeaf={isLeaf}
        expanded={isExpanded}
        onExpandAction={onExpandAction}
      />
      <Checkbox
        pl={3}
        variant="outline"
        checked={isChecked}
        indeterminate={isIndeterminate}
        onChange={handleCheckChange}
        disabled={!checkDirectly ?? !node?.checkable}
        icon={checkboxIcon}
        styles={{
          root: {
            ...(blockNode && {
              width: '100%',
            }),
          },
          inner: {
            ...(!checkDirectly && {
              display: 'none',
            }),
          },
          labelWrapper: {
            ...(blockNode && {
              width: '100%',
            }),
          },
          label: {
            paddingLeft: 0,
            ...(!checkDirectly && {
              color: 'inherit',
              cursor: 'default',
            }),
            ...(checkDirectly && {
              paddingLeft: 5,
            }),
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            ...(blockNode && {
              width: '100%',
            }),
          },
          body: {
            alignItems: 'center',
            flexWrap: 'nowrap',
          },
        }}
        label={
          <Group
            wrap="nowrap"
            align="center"
            m={0}
            p={0}
            gap={3}
            onClick={handleSelectChange}
            style={{
              ...(blockNode && {
                width: '100%',
              }),
              ...(isSelected && {
                background: 'var(--mantine-primary-color-light)',
                boxShadow: '0 0 0 3px var(--mantine-primary-color-light)',
                borderRadius: 1,
                borderCollapse: 'collapse',
              }),
            }}
          >
            {typeof Icon === 'function' && Icon.prototype.isReactComponent ? <Icon {...props} /> : Icon}
            {titleRender?.(node) || node[fieldNames.title]}
          </Group>
        }
      />
    </Flex>
  );

  return (
    <Stack m={0} p={0} gap={0}>
      {selector}
      {!isLeaf && isExpanded && (
        <Box ml="md">
          {node[fieldNames.children].map((child) => (
            <MantineTreeNode<TreeDataType>
              {...props}
              node={child}
              fieldNames={fieldNames}
              key={child[fieldNames.key]}
              id={child[fieldNames.key]}
            />
          ))}
        </Box>
      )}
    </Stack>
  );
};
