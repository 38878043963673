import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { UseListState } from '@mantine/hooks/lib/use-list-state/use-list-state';
import { ChatMessageDto, ChatMessageType, SemanticHistoryEntryDto } from '../../../services/graphql/apolloAppClient';
import {
  useGenerateReplyToUserMessageMutation,
  usePostReplyToChatMutation,
} from '../../../services/graphql/urqlAppClient';
import { useSearchHistoryEntryStore } from '../SearchHistoryEntryStoreProvider';
import { useDebounce, useDidMountEffect } from '../../../hooks';

export interface IUseChatStoreResult {
  messages: UseListState<ChatMessageDto>[0];
  editableUserMessage: ChatMessageDto | null;
  setEditableUserMessage: React.Dispatch<React.SetStateAction<ChatMessageDto | null>>;
  onReplyToChat: (message: string, messageId?: string) => any;
  onGenerateReplyToUserMessage: (id: string) => any;
  postReplyToChatStreamingId: string | undefined;
  generateReplyToUserMessageStreamingId: string | undefined;
  // autoGenerateReplyToUserMessage: () => any;
}

export const ChatStoreContext = createContext<IUseChatStoreResult | undefined>(undefined);
ChatStoreContext.displayName = 'ChatStoreContext';

export const useChatStore = (): IUseChatStoreResult => {
  const context = useContext(ChatStoreContext);
  if (!context) {
    console.warn('useChatStoreContext must be used within ChatStoreProvider');
  }
  return context as IUseChatStoreResult;
};

export const makeStreamMessage = ({
  id,
  type,
  text,
}: Omit<ChatMessageDto, 'rating' | 'createdAt'>): ChatMessageDto => ({
  __typename: 'ChatMessageDto',
  rating: {
    __typename: 'RatingDto',
    rating: 0,
    vote: null,
  },
  id,
  type,
  text,
  createdAt: new Date().toISOString(),
});

export const ChatStoreProvider = ({ children }) => {
  const { searchHistoryEntryStore } = useSearchHistoryEntryStore();

  const [messages, setMessages] = useState<ChatMessageDto[]>(
    (searchHistoryEntryStore?.data?.query as SemanticHistoryEntryDto).chat,
  );

  useEffect(() => {
    setMessages((searchHistoryEntryStore?.data?.query as SemanticHistoryEntryDto).chat);
  }, [(searchHistoryEntryStore?.data?.query as SemanticHistoryEntryDto)?.chat]);

  const [editableUserMessage, setEditableUserMessage] = useState<IUseChatStoreResult['editableUserMessage']>(null);

  const [generateReplyToUserMessageStore, generateReplyToUserMessage] = useGenerateReplyToUserMessageMutation();
  // const [generateReplyToUserMessage, generateReplyToUserMessageStore] = useGenerateReplyToUserMessageMutationApollo();
  const [generateReplyToUserMessageStreamingId, setGenerateReplyToUserMessageStreamingId] = useState<
    string | undefined
  >(undefined);

  const onGenerateReplyToUserMessage = async (messageId: string) => {
    let messageIndex = 0;
    let userMessageId = messageId;
    const message = messages.find((m, index) => {
      if (m.id === messageId) {
        messageIndex = index;
        return true;
      }
      return false;
    });
    if (message?.type === ChatMessageType.System) {
      userMessageId = messages[messageIndex - 1].id;
    }
    const systemMessageId = uuidV4();
    const systemStreamMessage = makeStreamMessage({
      id: systemMessageId,
      type: ChatMessageType.System,
      text: '',
    });
    setGenerateReplyToUserMessageStreamingId(systemMessageId);
    setMessages((prevState) => {
      const msgIndex = prevState.findIndex((m) => m.id === userMessageId);
      if (msgIndex < 0) {
        return prevState;
      }
      const currentMessage = prevState[msgIndex];
      if (currentMessage.type === ChatMessageType.System) {
        return prevState;
      }
      const newState = prevState.slice(0, msgIndex + 1);
      return [...newState, systemStreamMessage];
    });
    await generateReplyToUserMessage({
      // variables: {
      input: {
        systemMessageId,
        userMessageId,
      },
      // },
    }).catch((e) => {
      console.warn('Generate Reply Error', { e });
    });
    setTimeout(() => {
      setGenerateReplyToUserMessageStreamingId(undefined);
    }, 50);
    void searchHistoryEntryStore.refetch();
  };

  useEffect(() => {
    if (!generateReplyToUserMessageStreamingId) {
      return;
    }
    setMessages((prevState) => {
      const prevMessages = prevState.slice(0, prevState.length - 1);
      let systemStreamMessage = prevState[prevState.length - 1];
      systemStreamMessage = {
        ...systemStreamMessage,
        text:
          generateReplyToUserMessageStore.data?.generateReplyToUserMessage.chatTokenStreamDto?.tokens
            ?.map((r) => r.token)
            .join('') ?? '',
      };
      return [...prevMessages, systemStreamMessage];
    });
  }, [generateReplyToUserMessageStore.data?.generateReplyToUserMessage.chatTokenStreamDto?.tokens]);

  const [postReplyToChatStore, postReplyToChat] = usePostReplyToChatMutation();
  // const [postReplyToChat, postReplyToChatStore] = usePostReplyToChatMutationApollo();
  const [postReplyToChatStreamingId, setPostReplyToChatStreamingId] = useState<string | undefined>(undefined);
  const onReplyToChat = async (message: string, messageId?: string) => {
    if (message.length >= 3 && searchHistoryEntryStore?.data?.query?.id) {
      const systemMessageId = uuidV4();
      setPostReplyToChatStreamingId(systemMessageId);
      const userMessageId = messageId ?? uuidV4();
      const userStreamMessage = makeStreamMessage({ id: userMessageId, type: ChatMessageType.User, text: message });
      const systemStreamMessage = makeStreamMessage({
        id: systemMessageId,
        type: ChatMessageType.System,
        text: '',
      });
      setMessages((prevState) => {
        if (messageId) {
          const msgIndex = prevState.findIndex((m) => m.id === messageId);
          if (msgIndex < 0) {
            return prevState;
          }
          const newState = prevState.slice(0, msgIndex);
          return [...newState, userStreamMessage, systemStreamMessage];
        }
        return [...prevState, userStreamMessage, systemStreamMessage];
      });
      await postReplyToChat({
        // variables: {
        input: {
          userMessage: message,
          queryId: searchHistoryEntryStore.data.query.id, // ID of search history entry
          userMessageId, // id for user manges
          systemMessageId, // id for reply
        },
        // },
      }).catch((e) => {
        console.warn('Post Reply Error', { e });
      });
      setTimeout(() => {
        setPostReplyToChatStreamingId(undefined);
      }, 50);
      void searchHistoryEntryStore.refetch();
    }
  };

  useEffect(() => {
    if (!postReplyToChatStreamingId) {
      return;
    }
    setMessages((prevState) => {
      const prevMessages = prevState.slice(0, prevState.length - 1);
      let systemStreamMessage = prevState[prevState.length - 1];
      systemStreamMessage = {
        ...systemStreamMessage,
        text: postReplyToChatStore.data?.postReplyToChat.chatTokenStreamDto?.tokens?.map((r) => r.token).join('') ?? '',
      };
      return [...prevMessages, systemStreamMessage];
    });
  }, [postReplyToChatStore.data?.postReplyToChat.chatTokenStreamDto?.tokens]);

  const autoGenerateReplyToUserMessage = useDebounce(
    useMemo(
      () => async () => {
        if (!messages.length) {
          return;
        }
        const lastMessage = messages[messages.length - 1];
        if (lastMessage.type === ChatMessageType.System) {
          return;
        }
        await onGenerateReplyToUserMessage(lastMessage.id);
      },
      [messages, onGenerateReplyToUserMessage],
    ),
    100,
  );

  useEffect(() => {
    void autoGenerateReplyToUserMessage();
  }, [autoGenerateReplyToUserMessage]);

  const searchStoreContextObject: IUseChatStoreResult = useMemo(
    () => ({
      messages,
      editableUserMessage,
      setEditableUserMessage,
      postReplyToChatStreamingId,
      generateReplyToUserMessageStreamingId,
      onReplyToChat,
      onGenerateReplyToUserMessage,
    }),
    [messages, postReplyToChatStreamingId, generateReplyToUserMessageStreamingId, editableUserMessage],
  );

  return <ChatStoreContext.Provider value={searchStoreContextObject}>{children}</ChatStoreContext.Provider>;
};
