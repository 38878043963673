import { Button } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';
import { useSearchHistoryEntryStore } from '../SearchHistoryEntryStoreProvider';

export const RephraseButtons: React.FC = () => {
  const navigate = useNavigate();
  const { searchForm, folders, sendSearchQuery, sendSearchQueryStore } = useSearchHistoryEntryStore();
  const handleSearch = async () => {
    if (folders.data?.folders) {
      searchForm.setFieldValue(
        'filter.folders',
        folders.data?.folders.map((f) => f.id),
      );
      const id = uuidV4();
      await sendSearchQuery({
        variables: {
          input: {
            ...searchForm.values,
            filter: { folders: folders.data?.folders.map((f) => f.id) },
            id,
          },
        },
      }).catch((error) => {
        console.warn('Search error', { error });
      });
    }
  };

  return (
    <>
      <Button
        variant="transparent"
        onClick={() => {
          navigate('/');
        }}
        styles={{ root: { paddingInline: 0, verticalAlign: 0 } }}
      >
        rephrase
      </Button>
      {folders.data?.folders && (
        <>
          {' '}
          or{' '}
          <Button
            variant="transparent"
            loading={sendSearchQueryStore.loading}
            onClick={() => {
              void handleSearch();
            }}
            styles={{ root: { paddingInline: 0, verticalAlign: 0 } }}
          >
            search in all collections
          </Button>
        </>
      )}
    </>
  );
};

// export const RephraseButtons = () => {
//   const { searchHistoryEntryStore } = useSearchHistoryEntryStore();
//   if (searchHistoryEntryStore?.data?.query) {
//     return (
//       <RephraseButtonsComponent
//         type={
//           searchHistoryEntryStore.data.query.__typename === 'SemanticHistoryEntryDto'
//             ? SearchTypeEnum.AiSearch
//             : searchHistoryEntryStore.data.query.__typename === 'KeywordHistoryEntryDto'
//             ? SearchTypeEnum.KeywordSearch
//             : SearchTypeEnum.Auto
//         }
//         query={searchHistoryEntryStore.data.query.query}
//         entryFolders={searchHistoryEntryStore.data.query.folders}
//       />
//     );
//   }
//   return null;
// };
