import { AppShell, createTheme, MantineColorsTuple, MantineThemeOverride, rem } from '@mantine/core';

const egwPrimary: MantineColorsTuple = [
  '#e5faff',
  '#d3f2fb',
  '#a7e3f6',
  '#79d3f1',
  '#55c7ec',
  '#42beea',
  '#34baea',
  '#25a5d0',
  '#0f92bb',
  '#007fa5',
];

export interface GetAppThemesProps {
  isDarkMode?: boolean;
  isMobileLayout?: boolean;
  isTargetWidthLayout?: boolean;
}
export const getMantineTheme = (props?: GetAppThemesProps): MantineThemeOverride => {
  return createTheme({
    fontSmoothing: false,
    primaryColor: 'egwPrimary',
    primaryShade: { light: 9, dark: 9 },
    other: {
      egwPrimaryColor: '#0E89AF',
    },
    fontSizes: {
      ...(props?.isMobileLayout
        ? {
            xs: rem(14),
            sm: rem(16),
            md: rem(18),
            lg: rem(20),
            xl: rem(22),
          }
        : {}),
      copyrights: props?.isMobileLayout ? rem(8) : rem(12),
      appTitle: rem(28),
    },
    colors: {
      egwPrimary,
    },
    components: {
      AppShell: AppShell.extend({
        defaultProps: {
          padding: !props?.isMobileLayout ? 'md' : 'calc(0.25rem * var(--mantine-scale))',
          header: { height: 50 },
          footer: !props?.isMobileLayout ? { height: 50 } : undefined,
        },
        styles: {
          main: { height: '100dvh' },
          header: {
            zIndex: 200,
            backgroundColor: egwPrimary[9],
            paddingLeft: 'var(--mantine-spacing-xs)',
            paddingRight: 'var(--mantine-spacing-xs)',
          },
          footer: {
            zIndex: 200,
            backgroundColor: egwPrimary[9],
            color: '#FFFFFF',
            paddingLeft: 'var(--mantine-spacing-xs)',
            paddingRight: 'var(--mantine-spacing-xs)',
          },
          navbar: {
            border: 0,
            backgroundColor: `light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-8))`,
          },
        },
      }),
    },
  });
};

export interface GetAppThemesResults {
  mantineTheme: MantineThemeOverride;
}

export const getAppThemes = (props?: GetAppThemesProps): GetAppThemesResults => {
  return { mantineTheme: getMantineTheme(props) };
};
