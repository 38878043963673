import React, { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  InMemoryCache,
  ServerError,
  concat,
  createHttpLink,
} from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { CONSTANTS } from '../config';
import { apolloErrorsHandler } from '../utils';
import { useAppAuth } from './AppAuthenticationServiceProvider';

export const ApolloGraphqlAppApiClientProvider = ({ children }) => {
  const { user, removeUser } = useAppAuth();

  const httpLink = createHttpLink({
    uri: `${CONSTANTS.APP_API_URL}/graphql`,
    headers: {
      authorization: user?.access_token ? `Bearer ${user?.access_token}` : '',
      Accept: 'multipart/mixed; deferSpec=20220824, application/json',
      // 'GraphQL-Preflight': 1,
    },
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Accept: 'multipart/mixed; deferSpec=20220824, application/json',
        // 'GraphQL-Preflight': 1,
        authorization: user?.access_token ? `Bearer ${user?.access_token}` : '',
      },
    }));

    return forward(operation);
  });

  const errorLink = onError((error: ErrorResponse) => {
    const { graphQLErrors, networkError, operation, forward } = error;
    // if (error?.operation?.operationName === 'GetLegacyUserInfo') {
    //   return;
    // }
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Accept: 'multipart/mixed; deferSpec=20220824, application/json',
        // 'GraphQL-Preflight': 1,
        authorization: user?.access_token ? `Bearer ${user?.access_token}` : '',
      },
    }));
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const { message, locations, path, extensions } = err;
        switch (extensions?.code) {
          case 'AUTH_NOT_AUTHORIZED':
            // void removeUser?.().then(() => {
            //   apolloErrorsHandler(graphQLErrors);
            // });
            // forward(operation);
            // void removeUser();
            // modal.open({
            //   closeOnEscape: false,
            //   closeOnClickOutside: false,
            //   withCloseButton: false,
            //   children: (
            //     <Flex gap={5} h="100%" align="center" justify="start" style={{ flexDirection: 'column' }}>
            //       <Alert variant="light" color="red" title="AUTH NOT AUTHORIZED" icon={<IconInfoCircle />}>
            //         {message}
            //       </Alert>
            //       <Button
            //         onClick={() => {
            //           void removeUser();
            //         }}
            //       >
            //         Sign in now
            //       </Button>
            //     </Flex>
            //   ),
            // });
            break;
          default:
            apolloErrorsHandler(graphQLErrors);
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      if ((networkError as ServerError)?.result) {
        apolloErrorsHandler(networkError);
      }
    }
  });

  const client = new ApolloClient({
    link: from([errorLink, concat(authMiddleware, httpLink)]),
    name: 'ChatEGWWebAppApiClient',
    cache: new InMemoryCache({
      possibleTypes: {
        HistoryEntry: ['KeywordHistoryEntryDto', 'SemanticHistoryEntryDto'],
      },
      typePolicies: {
        KeywordSearchResultDto: {
          fields: {
            uri: {
              read(originalUri) {
                if (!originalUri) {
                  return originalUri;
                }
                const location = new URL(originalUri || 'https://example.com');
                if (location.host === 'egw.bz') {
                  const paths = location.pathname.split('/');
                  if (paths[0] === 'p' && !!paths[1]) {
                    return `https://egwwritings.org/read?panels=${paths.join('')}`;
                  }
                }
                return originalUri;
              },
            },
          },
        },
        SemanticSearchResultDto: {
          fields: {
            uri: {
              read(originalUri) {
                if (!originalUri) {
                  return originalUri;
                }
                const location = new URL(originalUri || 'https://example.com');
                if (location.host === 'egw.bz') {
                  const paths = location.pathname.split('/');
                  if (paths[0] === 'p' && !!paths[1]) {
                    return `https://egwwritings.org/read?panels=${paths.join('')}`;
                  }
                }
                return originalUri;
              },
            },
          },
        },
      },
    }),
    connectToDevTools: process.env.NODE_ENV === 'development',
    queryDeduplication: true,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
