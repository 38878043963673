import { Key } from 'react';

export const copySearchResultsToClipboard = async (data: { text?: string; uriLabel?: string; uri?: string }[] = []) => {
  let htmlString = '';
  data.forEach((item, key, array) => {
    const { text, uriLabel, uri } = item;
    const href = uri ? `<a href="${uri}">${uriLabel}</a>` : `<span>${uriLabel}</span>`;
    if (array.length > 1) {
      const number = `<div style="width: 100%;text-align: center">${key + 1}. </div>`;
      const content = `<li><p>${text}<br /><br />${href}</p></li>`;
      htmlString += key === 0 ? `<ol>${content}` : array.length === key + 1 ? `${content}</ol>` : content;
    } else {
      htmlString += `<p>${text}<br /><br />${href}</p>`;
    }
  });
  try {
    await navigator.clipboard.write([
      new ClipboardItem({
        'text/html': new Blob([htmlString], { type: 'text/html' }),
        'text/plain': new Blob([htmlString], { type: 'text/plain' }),
      }),
    ]);

    console.log('Copied to clipboard');
  } catch (e) {
    console.warn('Could not copy');
  }
};
