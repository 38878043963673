import React, { useEffect } from 'react';
import { IconChevronDown, IconChevronRight, IconTransactionBitcoin } from '@tabler/icons-react';
import { ActionIcon, Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MantineTreeSwitcherActionIconType } from './interface';

export const MantineTreeSwitcherActionIcon: MantineTreeSwitcherActionIconType = (props) => {
  const { expanded, isLeaf, switcherIcon: SwitcherIcon, onExpandAction, id } = props;
  const [opened, handlers] = useDisclosure(expanded, {
    onClose: () => onExpandAction?.(id, false),
    onOpen: () => onExpandAction?.(id, true),
  });

  useEffect(() => {
    if (expanded) {
      handlers.open();
      return;
    }
    handlers.close();
  }, [expanded]);

  if (isLeaf) {
    return (
      <ActionIcon variant="transparent" style={{ background: 'transparent' }} onClick={handlers.toggle} disabled />
    );
  }
  return (
    <ActionIcon variant="transparent" color="gray" onClick={handlers.toggle}>
      {SwitcherIcon ? <SwitcherIcon expanded={opened} /> : opened ? <IconChevronDown /> : <IconChevronRight />}
    </ActionIcon>
  );
};
