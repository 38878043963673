import React from 'react';
import { notifications } from '@mantine/notifications';
import { Affix, AffixProps, Button, ScrollArea } from '@mantine/core';
import { IconCopy } from '@tabler/icons-react';
import { copySearchResultsToClipboard } from '../../../utils';
import { useSearchHistoryEntryStore } from '../SearchHistoryEntryStoreProvider';

export const CopyAllResultsToClipboard = (props: AffixProps) => {
  const { searchHistoryEntryStore } = useSearchHistoryEntryStore();
  if (
    searchHistoryEntryStore.data?.query?.searchResults &&
    searchHistoryEntryStore.data?.query?.searchResults?.length < 1
  ) {
    return null;
  }

  return (
    <Affix pos="absolute" zIndex={87} position={{ bottom: 20, right: 20 }} {...props}>
      <Button
        leftSection={<IconCopy />}
        variant="default"
        styles={{
          root: {
            paddingInline: 'calc(var(--button-padding-x) / 1.5)',
            // border: 'none',
            // backgroundColor: 'transparent',
          },
          section: {
            // marginRight: 0,
          },
        }}
        onClick={() => {
          copySearchResultsToClipboard(
            searchHistoryEntryStore?.data?.query?.searchResults?.map((i) => ({
              text: i.fullText || i.snippet,
              uriLabel: i.referenceCode,
              uri: i.uri,
            })),
          ).then(async () => {
            const clipboard = await navigator.clipboard.readText();
            notifications.show({
              title: 'Result copied to clipboard',
              autoClose: 2000,
              message: (
                <ScrollArea.Autosize mah={150}>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: clipboard }} />
                </ScrollArea.Autosize>
              ),
            });
          });
        }}
      >
        Copy All
      </Button>
    </Affix>
  );
};
