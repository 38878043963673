import React from 'react';
import { ActionIcon, Text, Group, GroupProps, LoadingOverlay } from '@mantine/core';
import { IconThumbDown, IconThumbUp } from '@tabler/icons-react';
import { RatingDto, VoteTypeEnum } from '../services/graphql/apolloAppClient';

export interface VoteSectionProps extends GroupProps {
  rating?: RatingDto;
  disabled?: boolean;
  loading?: boolean;
  onVote: (vote: VoteTypeEnum | null) => void;
}

export const VoteSection: React.FC<VoteSectionProps> = (props) => {
  const { rating, disabled, onVote, loading, ...groupProps } = props;
  const handleVoteUp = () => {
    if (rating?.vote === VoteTypeEnum.Upvote) {
      return;
    }
    onVote(rating?.vote === VoteTypeEnum.Downvote ? null : VoteTypeEnum.Upvote);
  };
  const handleVoteDown = () => {
    if (rating?.vote === VoteTypeEnum.Downvote) {
      return;
    }
    onVote(rating?.vote === VoteTypeEnum.Upvote ? null : VoteTypeEnum.Downvote);
  };

  const ratingValue = rating?.rating ?? 0;
  const textColor = ratingValue < 0 ? 'red' : ratingValue > 0 ? 'green' : undefined;
  return (
    <Group gap={5} wrap="nowrap" {...groupProps}>
      <VoteActionIcon
        vote={rating?.vote}
        iconType={VoteTypeEnum.Downvote}
        disabled={disabled}
        onClick={handleVoteDown}
      />
      <Text c={textColor} pos="relative">
        <LoadingOverlay visible={loading} zIndex={77} overlayProps={{ radius: 'sm', blur: 2 }} />
        {ratingValue}
      </Text>
      <VoteActionIcon vote={rating?.vote} iconType={VoteTypeEnum.Upvote} disabled={disabled} onClick={handleVoteUp} />
    </Group>
  );
};

const VoteActionIcon = ({
  vote,
  iconType,
  onClick,
  disabled,
}: {
  vote?: RatingDto['vote'];
  iconType: VoteTypeEnum;
  onClick: (vote: VoteTypeEnum) => void;
  disabled?: boolean;
}) => {
  const isSelected = vote === iconType;
  const color = iconType === VoteTypeEnum.Downvote ? 'red' : 'green';
  const iconStyle = { width: 'var(--badge-fz)', height: 'var(--badge-fz)' };

  const handleClick = () => {
    onClick(iconType);
  };
  return (
    <ActionIcon
      disabled={disabled}
      // size="xs"
      variant="subtle"
      color={isSelected ? color : 'gray'}
      styles={{
        root: {
          border: 'none',
          ...(isSelected ? { pointerEvents: 'none', cursor: 'default' } : {}),
        },
      }}
      onClick={handleClick}
    >
      {iconType === VoteTypeEnum.Upvote ? <IconThumbUp /> : <IconThumbDown />}
    </ActionIcon>
  );
};
